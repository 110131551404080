import {
  PrizeDistribution,
  GoalBased,
  MetricFilter,
  MetricField,
} from '../types/leaderboard'

export const MetricFieldTournamentConfig: Record<string, string> = {
  Bigblind: 'Big Blind',
  PointRate: 'Point Rate',
}

export const LeaderboardType: Record<string, string> = {
  RankBased: 'Rank Based',
  GoalBased: 'Goal Based',
}

export const calculateCurrentGoal = (
  prizeDist: PrizeDistribution,
  scoreString: number
) => {
  let goal = 0

  const goal_based = [...prizeDist.goal_based]

  goal_based.sort(function (a, b) {
    return a.winning_score - b.winning_score
  })

  const score = Math.ceil(scoreString)
  for (let i = 0; i < goal_based.length; i++) {
    const g = goal_based[i]
    if (score < g.winning_score) {
      goal = Math.ceil(g.goal)
      break
    }

    if (i === goal_based.length - 1 && score >= g.winning_score) {
      goal = Math.ceil(g.goal) + 1
    }
  }

  if (goal === 0 && score > 0) {
    goal = 1
  }

  return goal
}

export const getLastGoal = (prizeDist: PrizeDistribution) => {
  let goal: GoalBased = {
    goal: 0,
    winning_score: 0,
    prize: {
      winning_amount: 0,
      display_value: '',
      wallet_type: '',
    },
    goal_name: '',
  }
  prizeDist.goal_based.sort(function (a, b) {
    return a.winning_score - b.winning_score
  })

  if (prizeDist.goal_based.length > 0) {
    goal = prizeDist.goal_based[prizeDist.goal_based.length - 1]
  }
  return goal
}

export const getGameName = (metricFilters: MetricFilter[]) => {
  let gameName = 'Poker'
  metricFilters.forEach(m => {
    if (m.metric_field.toString() === 'GameName') {
      gameName = m.value_range?.string_value || 'Poker'
    }
  })

  return gameName
}

export const getBBOrPr = (metricFilters: MetricFilter[]) => {
  let bbOrPR = 0
  metricFilters.forEach(m => {
    if (
      m.metric_field.toString() === MetricField.Bigblind.toString() ||
      m.metric_field.toString() === MetricField.PointRate.toString()
    ) {
      bbOrPR = m.value_range?.number_max
    }
  })

  return bbOrPR
}

export let bannerIndexTypeMap: any = {
  1: {
    0: { type: 'large', isLast: true },
  },
  2: {
    0: { type: 'medium', isLast: false },
    1: { type: 'medium', isLast: true },
  },
  3: {
    0: { type: 'medium', isLast: false },
    1: { type: 'small', isLast: false },
    2: { type: 'small', isLast: true },
  },
  4: {
    0: { type: 'medium', isLast: false },
    1: { type: 'medium', isLast: true },
    2: { type: 'medium', isLast: false },
    3: { type: 'medium', isLast: true },
  },
  5: {
    0: { type: 'medium', isLast: false },
    1: { type: 'medium', isLast: true },
    2: { type: 'medium', isLast: false },
    3: { type: 'small', isLast: false },
    4: { type: 'small', isLast: true },
  },
  6: {
    0: { type: 'medium', isLast: false },
    1: { type: 'medium', isLast: true },
    2: { type: 'small', isLast: false },
    3: { type: 'small', isLast: false },
    4: { type: 'small', isLast: false },
    5: { type: 'small', isLast: true },
  },
}
