import React, { useEffect, useState, useCallback } from 'react'

import Loader from 'react-loader-spinner'
import styles from './PurchaseCard.module.scss'
import {
  getWalletBalance,
  purchaseLbEntry,
} from '../../containers/LeaderboardDetails/api'
import CloseIcon from '../../assets/images/close.png'
import TickIcon from '../../assets/images/tick.png'
import { generateAddMoneyCTA } from '../../helpers/url-generator'
import { Offer } from '../../types/leaderboard'
import 'react-loader-spinner/dist/loader/css/react-spinner-loader.css'

interface Props {
  entryFee: number
  setShowPurchaseCard: Function
  lbId: string
  lbName: string
  fetchLeaderboardDetails: Function
  offer?: Offer
}

const PurchaseCard: React.FC<Props> = ({
  entryFee,
  setShowPurchaseCard,
  lbId,
  lbName,
  fetchLeaderboardDetails,
  offer,
}) => {
  const [balance, setBalance] = useState<number>(0)
  const [showPaymentCard, setShowPaymentCard] = useState<boolean>(true)
  const [err, setErr] = useState('')
  const offerId = offer ? offer.offer_id : ''
  const [loading, setLoading] = useState(true)
  const getPaidLbEntryFeeHtml = (entryFee: number, offer?: Offer) => {
    if (offer) {
      const feeAfterDisc = entryFee - offer.reward.Amount
      return (
        <div className={styles.rightText}>
          <span style={{ textDecoration: 'line-through', color: '#ED4F46' }}>
            <span style={{ color: 'black' }}>₹{entryFee}</span>
          </span>{' '}
          ₹{feeAfterDisc}
        </div>
      )
    }
    return <div className={styles.rightText}>₹{entryFee}</div>
  }

  const sendLeaderboardJoinSuccessCardShownEvent = (): void => {
    try {
      window.analytics.track('LeaderboardJoinSuccessCardShown', {
        leaderboard_id: lbId,
        source_screen: 'Paid Leaderboard pop up',
      })
    } catch (error) {
      console.error(error)
    }
    try {
      window.rudderanalytics.track('LeaderboardJoinSuccessCardShown', {
        leaderboard_id: lbId,
        source_screen: 'Paid Leaderboard pop up',
      })
    } catch (error) {
      console.error(error)
    }
  }

  const payNow =
    (entryFee: number, balance: number, lbId: string, offerId: string) =>
    async () => {
      if (balance > entryFee) {
        setLoading(true)
        const resp = await purchaseLbEntry(lbId, offerId)
        setLoading(false)
        if (!resp.error) {
          setShowPaymentCard(false)
          sendLeaderboardJoinSuccessCardShownEvent()
        } else {
          setErr(resp.error)
        }
      }

      // send event
      try {
        window.analytics.track(
          entryFee > balance
            ? 'LeaderboardAddMoneyClicked'
            : 'LeaderboardPayNowClicked',
          {
            leaderboard_id: lbId,
            balance,
            entry_fee: entryFee,
            source_screen: 'Paid Leaderboard pop up',
          }
        )
      } catch (error) {
        console.error(error)
      }
      try {
        window.rudderanalytics.track(
          entryFee > balance
            ? 'LeaderboardAddMoneyClicked'
            : 'LeaderboardPayNowClicked',
          {
            leaderboard_id: lbId,
            balance,
            entry_fee: entryFee,
            source_screen: 'Paid Leaderboard pop up',
          }
        )
      } catch (error) {
        console.error(error)
      }
    }

  const closePurchaseCard = () => {
    setShowPurchaseCard(false)

    // send event
    try {
      window.analytics.track('EnterTournamentCardClosed', {
        leaderboard_id: lbId,
        source_screen: 'Paid Leaderboard pop up',
      })
    } catch (error) {
      console.error(error)
    }
    try {
      window.rudderanalytics.track('EnterTournamentCardClosed', {
        leaderboard_id: lbId,
        source_screen: 'Paid Leaderboard pop up',
      })
    } catch (error) {
      console.error(error)
    }
  }

  const sendEnterTournamentCardViewedEvent = useCallback(
    (bal: number): void => {
      try {
        window.analytics.track('EnterTournamentCardViewed', {
          leaderboard_id: lbId,
          entry_fee: entryFee,
          balance: bal,
          source_screen: 'Paid Leaderboard pop up',
        })
      } catch (error) {
        console.error(error)
      }
      try {
        window.rudderanalytics.track('EnterTournamentCardViewed', {
          leaderboard_id: lbId,
          entry_fee: entryFee,
          balance: bal,
          source_screen: 'Paid Leaderboard pop up',
        })
      } catch (error) {
        console.error(error)
      }
    },
    [entryFee, lbId]
  )

  useEffect(() => {
    const walletBalance = async () => {
      setLoading(true)
      const resp = await getWalletBalance()
      setLoading(false)
      if (!resp.error) {
        const bal = resp.balance.toFixed(2)
        setBalance(bal)
        sendEnterTournamentCardViewedEvent(bal)
      } else {
        setErr(resp.error)
      }
    }
    walletBalance()
  }, [sendEnterTournamentCardViewedEvent])

  if (loading) {
    return (
      <div id={styles.PurchaseCard}>
        <div className={styles.bottomCard} style={{ alignItems: 'center' }}>
          <div className={styles.loader}>
            <Loader type="Oval" color="#000000" height={100} width={100} />
          </div>
        </div>
      </div>
    )
  }

  return (
    <div id={styles.PurchaseCard}>
      <div className={styles.bottomCard}>
        {err ? (
          <>
            <div
              className={styles.header}
              style={{ justifyContent: 'flex-end' }}
            >
              <img
                className={styles.crossIcon}
                src={CloseIcon}
                onClick={closePurchaseCard}
                alt="close"
              />
            </div>
            <span className={styles.err}>{err}</span>
          </>
        ) : showPaymentCard ? (
          <>
            <div className={styles.header}>
              <span className={styles.headerText}>Enter Tournament</span>
              <img
                className={styles.crossIcon}
                src={CloseIcon}
                onClick={closePurchaseCard}
                alt="close"
              />
            </div>
            <div className={styles.body}>
              <div className={styles.row}>
                <span className={styles.leftText}>Entry Fee</span>
                {getPaidLbEntryFeeHtml(entryFee, offer)}
              </div>
              <div className={styles.row}>
                <span className={styles.leftText}>Balance</span>
                <span className={styles.rightText}>₹{balance}</span>
              </div>
            </div>
            {!err && (
              <a
                className={styles.payNow}
                href={generateAddMoneyCTA(balance, entryFee, lbId, offer)}
                onClick={payNow(entryFee, balance, lbId, offerId)}
              >
                {entryFee > balance ? 'Add Money' : 'Pay Now'}
              </a>
            )}
          </>
        ) : (
          <>
            <div
              className={styles.header}
              style={{ justifyContent: 'flex-end' }}
            >
              <img
                className={styles.crossIcon}
                src={CloseIcon}
                onClick={closePurchaseCard}
                alt="close"
              />
            </div>
            <div className={styles.body} style={{ alignItems: 'center' }}>
              <img className={styles.tick} src={TickIcon} alt="tick" />
              <span className={styles.success}>
                Yey! You are now part of the{' '}
                <span className={styles.lbName}>{lbName}</span>! Keep playing
                and keep winning
              </span>
            </div>
            <a
              className={styles.payNow}
              onClick={() => {
                fetchLeaderboardDetails(lbId)
                setShowPurchaseCard(false)
              }}
            >
              Go Back
            </a>
          </>
        )}
      </div>
    </div>
  )
}

export default PurchaseCard
