// @ts-ignore
import Carousel from 'infinite-react-carousel'
import React, { useState } from 'react'

import styles from './Testimonials.module.scss'

interface Props {
  testimonials: Testimonial[]
  lbId: string
}

interface Testimonial {
  text: string
  profile_url: string
  name: string
  rank: string
}

const Testimonials: React.FC<Props> = ({ testimonials, lbId }) => {
  const [index, setIndex] = useState(0)

  return (
    <Carousel
      dots
      arrows={false}
      className={styles['infinite-carousel']}
      onSwipe={(dir: string): void => {
        let i = 0
        if (dir === 'left') {
          i = (index - 1 + testimonials.length) % testimonials.length
        } else {
          i = (index + 1) % testimonials.length
        }
        setIndex(i)

        try {
          window.analytics.track('ToggleReviews', {
            leaderboard_id: lbId,
            commenter_id: testimonials[i].profile_url,
            comment: testimonials[i].text,
            source_screen: 'My leaderboard detail',
          })
        } catch (error) {
          console.error(error)
        }
        try {
          window.rudderanalytics.track('ToggleReviews', {
            leaderboard_id: lbId,
            commenter_id: testimonials[i].profile_url,
            comment: testimonials[i].text,
            source_screen: 'My leaderboard detail',
          })
        } catch (error) {
          console.error(error)
        }
      }}
    >
      {testimonials.map(t => (
        <div className={styles['mega-carousel']} key={t.text}>
          <div className={styles['image-text-div']}>
            <img
              className={styles.carouselImage}
              src={t.profile_url}
              alt="contestant"
              width={30}
              height={30}
            />
            <div className={styles['carousel-header']}>
              <span className={styles.name}>{t.name}</span>
              <span className={styles.joinDate}>
                Rank
                {t.rank}
              </span>
            </div>
          </div>
          <span className={styles.description}>{t.text}</span>
        </div>
      ))}
    </Carousel>
  )
}

export default Testimonials
