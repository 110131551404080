import { Leaderboard } from '../../types/leaderboard'

export const getWinningAmount = (leaderboard: Leaderboard): number => {
  // if leaderboard result is declared
  let winningAmount = 0

  // winning amount for rank based
  if (!!leaderboard.prize_distribution.rank_based) {
    if (
      leaderboard.player_rank <=
        leaderboard.prize_distribution.rank_based[
          leaderboard.prize_distribution.rank_based.length - 1
        ].start_rank ||
      leaderboard.player_rank <=
        leaderboard.prize_distribution.rank_based[
          leaderboard.prize_distribution.rank_based.length - 1
        ].end_rank
    ) {
      for (
        let i = 0;
        i < leaderboard.prize_distribution.rank_based.length;
        i += 1
      ) {
        if (
          leaderboard.player_rank >=
            leaderboard.prize_distribution.rank_based[i].start_rank &&
          leaderboard.player_rank <=
            leaderboard.prize_distribution.rank_based[i].end_rank &&
          leaderboard.prize_distribution.rank_based[i].qualification_score <=
            (leaderboard.player_score || 0)
        )
          winningAmount =
            leaderboard.prize_distribution.rank_based[i].prize.winning_amount
      }
    }
  }

  if (!!leaderboard.prize_distribution.goal_based) {
    leaderboard.player_score = !!leaderboard.player_score
      ? leaderboard.player_score
      : 0

    leaderboard.prize_distribution.goal_based.sort(function (a, b) {
      return a.winning_score - b.winning_score
    })

    leaderboard.prize_distribution.goal_based.forEach(g => {
      if (leaderboard.player_score >= g.winning_score) {
        winningAmount += g.prize.winning_amount
      }
    })
  }

  return winningAmount
}

export default getWinningAmount
