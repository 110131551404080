import React, { useEffect, useState, useCallback } from 'react'
import { RouteComponentProps } from '@reach/router'
import styles from './LeaderboardsList.module.scss'
import Empty from '../../components/Empty/Empty'
import LeaderboardCard from '../../components/LeaderboardCard/LeaderboardCard'
import { Leaderboard } from '../../types/leaderboard'
import { getMyLeaderboards } from './api'
import { useDate } from './DateContext'

type Props = RouteComponentProps<{
  path: string
  filterParams: string
}>

const MyLeaderboards: React.FC<Props> = props => {
  const [joinedLbs, setJoinedLbs] = useState<Leaderboard[]>([])
  const { date } = useDate()!
  const [, setMyLbError] = useState('')

  const fetchMyLeaderboards = async (): Promise<Leaderboard[]> => {
    const { leaderboards: lbs, error } = await getMyLeaderboards()
    setJoinedLbs(lbs)
    setMyLbError(error)

    return lbs
  }

  const fetchLbs = useCallback(async (): Promise<void> => {
    await Promise.all([fetchMyLeaderboards()]).then(values => {
      return values
    })
  }, [])

  useEffect(() => {
    fetchLbs()
  }, [fetchLbs])

  return (
    <div id={styles.LeaderboardsList}>
      <div className={styles.PageTitle}>My Leaderboards</div>

      {joinedLbs?.length > 0 ? (
        <div style={{ padding: '24px 16px 24px' }}>
          {joinedLbs.map(lb => {
            return (
              <React.Fragment key={lb.leaderboard_id}>
                <LeaderboardCard leaderboard={lb} date={date} />
                <div style={{ height: '12px' }} />
              </React.Fragment>
            )
          })}
        </div>
      ) : (
        <div style={{ padding: '24px 66px 12px' }}>
          <Empty message="Your leaderboards will appear here. Join new leaderboards and win Big from section below!" />
        </div>
      )}
    </div>
  )
}

export default MyLeaderboards
