import React from 'react'
import styles from './PreviousWinners.module.scss'

interface Props {
  previousWinners: PreviousWinner[]
}

interface PreviousWinner {
  amount: string
  winnings: string
  profile_pic_url: string
  name: string
  prize: string
}

const PreviousWinners: React.FC<Props> = ({ previousWinners }) => {
  return (
    <div className={styles.pWinnersDiv} id="pWinnersDiv">
      <span className={styles.mainHeader}>Last Week Winners</span>
      {previousWinners.map(p => {
        const amount = parseInt(p.amount, 10)
        const winnings = parseInt(p.winnings, 10)
        return (
          <div key={p.profile_pic_url} className={styles.mainRow}>
            <img
              className={styles.mainImage}
              src={p.profile_pic_url}
              alt={p.name}
            />
            <span className={styles.mainText}>
              <span className={styles.pText}>{p.name} </span>
              won the {p.prize} prize of
              <span className={styles.pAmount}>
                {' '}
                ₹{amount.toLocaleString('en-IN')}{' '}
              </span>
              cash, as his best Daily Winnings was
              <span className={styles.pWinnings}>
                {' '}
                ₹{winnings.toLocaleString('en-IN')}
              </span>
            </span>
          </div>
        )
      })}
    </div>
  )
}

export default PreviousWinners
