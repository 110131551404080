import React, { useState } from 'react'

import styles from './HowToWin.module.scss'
import Pill from '../Pill/Pill'
import { LeaderboardDetails } from '../../types/leaderboard-details'
import CollapsebaleSection from '../CollapseableSection/CollapseableSection'

interface Props {
  lbDetails: LeaderboardDetails
}

const pokerHands = [
  {
    hand: 'High Card',
    value: 1,
  },
  {
    hand: 'One Pair',
    value: 2,
  },
  {
    hand: 'Two Pair',
    value: 3,
  },
  {
    hand: 'Three of a Kind',
    value: 4,
  },
  {
    hand: 'Straight',
    value: 5,
  },
  {
    hand: 'Flush',
    value: 6,
  },
  {
    hand: 'Full House',
    value: 7,
  },
  {
    hand: 'Four of a Kind',
    value: 8,
  },
  {
    hand: 'Straight Flush',
    value: 9,
  },
  {
    hand: 'Royal Flush',
    value: 10,
  },
].reverse()

const HowToWin: React.FC<Props> = ({ lbDetails }) => {
  const selectedPillStyle: React.CSSProperties = {
    color: 'white',
    background: '#276EF1',
    border: '1px #276EF1 solid',
  }

  const [bb, setBB] = useState(
    lbDetails.scoring_system.auto_multiplier_base.value
  )
  let bbs = [lbDetails.scoring_system.auto_multiplier_base]
  if (lbDetails.scoring_system.additional_multipliers) {
    bbs = [...bbs, ...lbDetails.scoring_system.additional_multipliers]
  }

  const sendHowToWInButtonClickedEvent = (): void => {
    try {
      window.analytics.track('HowToWInButtonClicked', {
        leaderboard_id: lbDetails.leaderboard_id,
        source_screen: 'My leaderboard detail',
      })
    } catch (error) {
      console.error(error)
    }
    try {
      window.rudderanalytics.track('HowToWInButtonClicked', {
        leaderboard_id: lbDetails.leaderboard_id,
        source_screen: 'My leaderboard detail',
      })
    } catch (error) {
      console.error(error)
    }
  }

  return (
    <div id={styles.HowToWin}>
      <CollapsebaleSection
        title="How To Win"
        onHeaderClick={sendHowToWInButtonClickedEvent}
      >
        <div className={styles.text}>
          Participate and play contests of GetMega. Points system is different
          for every game
        </div>

        <div className={styles.text2}>
          Choose <span>Big Blind</span> to see points distribution
        </div>

        <div className={styles.pills}>
          {bbs.map(b => {
            return (
              <Pill
                key={b.value}
                style={bb === b.value ? selectedPillStyle : {}}
                onClick={(): void => {
                  setBB(b.value)
                }}
              >
                {b.value} Big Blind
              </Pill>
            )
          })}
        </div>

        <table className={styles.table}>
          <thead>
            <tr>
              <th>Best Hand</th>
              <th className={styles.right}>Score</th>
            </tr>
          </thead>
          <tbody>
            {pokerHands.map(h => {
              return (
                <tr key={h.hand}>
                  <td>{h.hand}</td>
                  <td className={styles.right}>
                    {h.value * (bbs.find(b => b.value === bb)?.multiplier || 1)}
                  </td>
                </tr>
              )
            })}
          </tbody>
        </table>
      </CollapsebaleSection>
    </div>
  )
}

export default HowToWin
