import React from 'react'
import styles from './PrizeInfo.module.scss'
import { LeaderboardDetails } from '../../types/leaderboard-details'
import { State, LbType } from '../../types/leaderboard'
import { LeaderboardEntry } from '../../types/leaderboard-entry'
import { getPlayerId } from '../../auth-token'
import { calculateCurrentGoal, getLastGoal } from '../../helpers/constants'

interface Props {
  lbDetails: LeaderboardDetails
  entries: LeaderboardEntry[]
}

const getBgForLeaderboard = (
  lbDetails: LeaderboardDetails,
  myEntry?: LeaderboardEntry
) => {
  let bg = '#276ef1'

  if (
    lbDetails.state === State.ResultDeclared &&
    lbDetails.type === LbType.RankBased.toString() &&
    (lbDetails?.player_rank >
      lbDetails?.prize_distribution.rank_based[
        lbDetails.prize_distribution.rank_based.length - 1
      ].end_rank ||
      (myEntry?.score && (myEntry?.prize === 0 || !myEntry?.prize)))
  ) {
    bg = '#ED4F46'
  }
  if (
    lbDetails.state === State.ResultDeclared &&
    lbDetails.type === LbType.GoalBased.toString()
  ) {
    const goal_based = lbDetails.prize_distribution.goal_based
    const currentGoal = calculateCurrentGoal(
      lbDetails.prize_distribution,
      myEntry?.score || 0
    )
    const finalGoal = currentGoal - 1

    if (finalGoal < goal_based.length) {
      return '#ED4F46'
    }
  }

  if (lbDetails.state === State.ResultDeclared && (myEntry?.prize || 0) > 0) {
    bg = '#32E0C7'
  }
  if (lbDetails.state === State.ResultDeclared && !lbDetails.player_rank) {
    bg = '#FFEFEE'
  }

  return bg
}

const getCardDetailsForLb = (
  lbDetails: LeaderboardDetails,
  myEntry?: LeaderboardEntry
) => {
  if (!lbDetails) return null
  // not finished lbs
  if (lbDetails?.state !== State.ResultDeclared) {
    if (lbDetails.type === LbType.RankBased.toString()) {
      return (
        <div className={styles.text}>
          {lbDetails.has_non_cash_prizes ? (
            <>
              <span>Prize Money of </span>
              <span className={styles.b}>
                {lbDetails?.prize_distribution.rank_based[0].prize
                  .display_value ||
                  `₹${lbDetails?.prize_distribution.rank_based[0].prize.winning_amount}`}
              </span>
              <span> for the winner of this leaderboard</span>
            </>
          ) : (
            <>
              <span>Prize Money of </span>
              <span className={styles.b}>
                {`₹${lbDetails?.prize_distribution.rank_based.reduce(
                  (sum: number, c) => {
                    return (
                      sum +
                      ((c.end_rank || c.start_rank) - c.start_rank + 1) *
                        c.prize.winning_amount
                    )
                  },
                  0
                )}`}
              </span>
              <span> for winning ranks in this leaderboard</span>
            </>
          )}
        </div>
      )
    }

    if (lbDetails.type === LbType.GoalBased.toString()) {
      return (
        <div className={styles.text}>
          <span>Guaranteed prize money of </span>
          <span className={styles.b}>
            {`₹${lbDetails?.prize_distribution.goal_based.reduce(
              (sum: number, c) => {
                return sum + +c.prize.winning_amount
              },
              0
            )}`}
          </span>
          <span> on completion of all goals</span>
        </div>
      )
    }
  }

  // finished lbs
  if (lbDetails.state === State.ResultDeclared) {
    if (lbDetails.type === LbType.RankBased.toString()) {
      let endRank =
        lbDetails?.prize_distribution.rank_based[
          lbDetails.prize_distribution.rank_based.length - 1
        ].end_rank
      if (
        lbDetails?.player_rank > endRank ||
        (myEntry?.score && (myEntry?.prize === 0 || !myEntry?.prize))
      ) {
        return (
          <div className={styles.text} style={{ color: 'white' }}>
            You missed the prize{' '}
            {lbDetails.prize_distribution.rank_based[0].prize.display_value ||
              `₹${lbDetails.prize_distribution.rank_based[0].prize.winning_amount}`}{' '}
            for this Leaderboard
          </div>
        )
      }
      if (lbDetails?.player_rank <= endRank && (myEntry?.prize || 0) > 0) {
        return (
          <div className={styles.text} style={{ color: 'black' }}>
            Yey! You won prize{' '}
            {!!myEntry.prize_display_value
              ? myEntry.prize_display_value
              : `₹${myEntry?.prize || 0} `}{' '}
            for this Leaderboard
          </div>
        )
      }

      if (!lbDetails.player_rank) {
        return (
          <div className={styles.text} style={{ color: 'black' }}>
            <div style={{ fontFamily: 'Gotham Rounded Medium' }}>
              You missed prize worth{' '}
              {lbDetails.prize_distribution.rank_based[0].prize.display_value ||
                `₹${lbDetails.prize_distribution.rank_based[0].prize.winning_amount}`}
            </div>
            <div>
              This leaderboard is over. You did NOT join this leaderboard
            </div>
          </div>
        )
      }
    }

    if (lbDetails.type === LbType.GoalBased.toString()) {
      let totalPrize = lbDetails?.prize_distribution.goal_based.reduce(
        (sum: number, c) => {
          return sum + +c.prize.winning_amount
        },
        0
      )
      const currentGoal = calculateCurrentGoal(
        lbDetails.prize_distribution,
        myEntry?.score || 0
      )

      const lastGoal = getLastGoal(lbDetails.prize_distribution)

      if (currentGoal <= Math.ceil(lastGoal.goal)) {
        return (
          <div className={styles.text} style={{ color: 'white' }}>
            You missed the prize money of ₹{totalPrize} on completion of all the
            goals
          </div>
        )
      }
      if (currentGoal > Math.ceil(lastGoal.goal)) {
        return (
          <div className={styles.text} style={{ color: 'black' }}>
            Yey! You won prize money of ₹{myEntry?.prize || 0} for this
            Leaderboard
          </div>
        )
      }

      if (currentGoal === 0) {
        return (
          <div className={styles.text} style={{ color: 'black' }}>
            <div style={{ fontFamily: 'Gotham Rounded Medium' }}>
              You missed prize worth ₹{totalPrize}
            </div>
            <div>
              This leaderboard is over. You did NOT join this leaderboard
            </div>
          </div>
        )
      }
    }
  }
}

const PrizeInfo: React.FC<Props> = ({ lbDetails, entries }) => {
  const myPlayerId = getPlayerId()
  const myEntry = entries.find(e => e.player.player_id === myPlayerId)

  let bg = getBgForLeaderboard(lbDetails, myEntry)

  return (
    <div className={styles.prizeInfo} style={{ background: bg }}>
      <div className={styles.trophy}>
        <span role="img" aria-label="trophy">
          🏆
        </span>
      </div>
      {getCardDetailsForLb(lbDetails, myEntry)}
    </div>
  )
}

export default PrizeInfo
