import React from 'react'
import styles from './Empty.module.scss'
import EmptyIcon from '../../assets/images/empty.png'

interface Props {
  message: string
}

const Empty: React.FC<Props> = props => {
  const { message } = props

  return (
    <div className={styles.Empty}>
      <div className={styles.image}>
        <img src={EmptyIcon} alt="empty" style={{ width: '100%' }} />
      </div>
      <div className={styles.message}>{message}</div>
    </div>
  )
}

export default Empty
