import React from 'react'
import { navigate } from '@reach/router'

import { Dayjs } from 'dayjs'
import styles from './LeaderboardCard.module.scss'
import { Leaderboard, State, LbType } from '../../types/leaderboard'
import { getWinningAmount } from './helpers'
import useCountdown from '../../hooks/useCountdown'
import RankBasedIcon from '../../assets/images/rank based.png'
import GoalBasedIcon from '../../assets/images/goal based.png'
import PaidIcon from '../../assets/images/paid.svg'
import { generateImageUrl } from '../../helpers/url-generator'
import {
  MetricFieldTournamentConfig,
  LeaderboardType,
  calculateCurrentGoal,
} from '../../helpers/constants'

interface Props {
  leaderboard: Leaderboard
  date: Dayjs
}

const LeaderboardCard: React.FC<Props> = ({ leaderboard, date }) => {
  const winningAmount = getWinningAmount(leaderboard)
  let timer = ''
  // time remaining for leaderboard
  const lbLiveTimer = useCountdown(new Date(leaderboard.close_time))
  // time when leaderboard opens
  const lbOpenTimer = useCountdown(new Date(leaderboard.live_time))
  if (leaderboard.state === State.Live) {
    timer = `${lbLiveTimer.days || '0'}d ${lbLiveTimer.hours || '0'}h ${
      lbLiveTimer.minutes || '0'
    }m ${lbLiveTimer.seconds || '0'}s left`
  } else if (
    leaderboard.state === State.Draft ||
    leaderboard.state === State.Open
  ) {
    timer = `Starts in ${lbOpenTimer.days || '0'}d ${
      lbOpenTimer.hours || '0'
    }h ${lbOpenTimer.minutes || '0'}m ${lbOpenTimer.seconds || '0'}s`
  }

  const sendEvent = (): void => {
    try {
      window.analytics.track('LeaderboardDetailsCardClicked', {
        date_selected: new Date(date.format()).toISOString(),
        leaderboard_id: leaderboard.leaderboard_id,
        comments_shown: [],
        source_screen: 'leaderboard details',
      })
    } catch (er) {
      console.error(er)
    }
    try {
      window.rudderanalytics.track('LeaderboardDetailsCardClicked', {
        date_selected: new Date(date.format()).toISOString(),
        leaderboard_id: leaderboard.leaderboard_id,
        comments_shown: [],
        source_screen: 'leaderboard details',
      })
    } catch (er) {
      console.error(er)
    }
  }

  const getPaidLbEntryFeeHtml = (leaderboard: Leaderboard) => {
    const entryFee = leaderboard.entry_fee?.amount || 0
    if (
      !!leaderboard.offers &&
      leaderboard.offers?.length > 0 &&
      leaderboard.state !== State.ResultDeclared
    ) {
      const offer = leaderboard.offers[0]
      const feeAfterDisc = entryFee - offer.reward.Amount
      return (
        <div className={styles.value}>
          <span style={{ textDecoration: 'line-through', color: '#ED4F46' }}>
            <span style={{ color: 'black' }}>₹{entryFee}</span>
          </span>{' '}
          ₹{feeAfterDisc}
        </div>
      )
    }
    return <div className={styles.value}>₹{entryFee}</div>
  }

  const iconForLeaderboard = (leaderboard: Leaderboard) => {
    if (leaderboard.category === 'PAID') {
      return PaidIcon
    }

    switch (leaderboard.type) {
      case LbType.RankBased.toString():
        return RankBasedIcon
      case LbType.GoalBased.toString():
        return GoalBasedIcon
      default:
        return RankBasedIcon
    }
  }

  let currentGoal = 0
  if (
    (leaderboard.state === State.Live ||
      leaderboard.state === State.ResultDeclared) &&
    leaderboard.type === LbType.GoalBased.toString()
  ) {
    currentGoal = calculateCurrentGoal(
      leaderboard.prize_distribution,
      leaderboard.player_score
    )
  }

  return (
    <div
      className={styles.LeaderboardCard}
      role="button"
      tabIndex={0}
      onClick={(): void => {
        sendEvent()
        navigate(`/leaderboard-details/${leaderboard.leaderboard_id}`)
      }}
      onKeyDown={(e): void => {
        if (e.key === 'Enter') {
          sendEvent()
          navigate('/leaderboard-details')
        }
      }}
    >
      {leaderboard.type === LbType.RankBased.toString() &&
        leaderboard.category === 'PAID' &&
        leaderboard.image_url && (
          <img
            src={generateImageUrl(leaderboard.image_url)}
            className={styles.imgContainer}
            alt={`${leaderboard.category} ${leaderboard.type}`}
          />
        )}
      <div style={{ padding: '20px 16px 16px' }}>
        <div className={styles.pageTitle}>
          <div className={styles.header}>{leaderboard.external_name}</div>
          <div className={styles.arrow}>→</div>
        </div>
        <div className={styles.details}>
          {leaderboard.filters.map(f => {
            if (
              f.metric_field.toString() === 'Bigblind' ||
              f.metric_field.toString() === 'PointRate'
            ) {
              return (
                <>
                  <span>
                    {MetricFieldTournamentConfig[f.metric_field.toString()]} ₹
                  </span>
                  <span>{f.value_range.number_min}</span>
                  {f.value_range.number_min !== f.value_range.number_max && (
                    <>
                      <span> - ₹</span>
                      <span>{f.value_range.number_max}</span>
                    </>
                  )}
                </>
              )
            }

            return <></>
          })}

          <span> • </span>
          {leaderboard.state === State.Draft ||
          leaderboard.state === State.Open ? (
            <span>{timer}</span>
          ) : null}
          {leaderboard.state === State.Live ? <span>{timer}</span> : null}
          {leaderboard.state === State.ComputingResult ? (
            <span>Computing results</span>
          ) : null}
          {leaderboard.state === State.ResultDeclared &&
          leaderboard.player_rank > 0 &&
          winningAmount === 0 ? (
            <span className={styles.lost}>You Lost</span>
          ) : null}
          {leaderboard.state === State.ResultDeclared &&
          !leaderboard.player_rank ? (
            <span className={styles.lost}>You did not play</span>
          ) : null}
          {leaderboard.state === State.ResultDeclared && winningAmount !== 0 ? (
            <span className={styles.won}>{`You won ₹${winningAmount}`}</span>
          ) : null}
        </div>

        <div className={styles.separator} />

        <div className={styles.moreDetails}>
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <div>
              <img
                src={iconForLeaderboard(leaderboard)}
                alt="rank based"
                className={styles.lbTypeIcon}
              />
            </div>
            <div>
              <div className={styles.key}>Type</div>
              <div className={styles.value}>
                {leaderboard.category === 'PAID'
                  ? 'Paid'
                  : LeaderboardType[leaderboard.type]}
              </div>
            </div>
          </div>

          {leaderboard.category === 'PAID' && (
            <div className={styles.entryFee}>
              <div>
                <div className={styles.key}>Entry</div>
                {getPaidLbEntryFeeHtml(leaderboard)}
              </div>
            </div>
          )}

          {leaderboard.state === State.Draft ||
          leaderboard.state === State.Open ? (
            <div>
              <div className={styles.key}>Players Joined</div>
              <div className={styles.value}>
                {leaderboard.total_participants || '0'}
              </div>
            </div>
          ) : null}

          {leaderboard.state === State.Live && !leaderboard.player_rank ? (
            <div>
              <div className={styles.key}>Players Joined</div>
              <div className={styles.value}>
                {leaderboard.total_participants || '0'}
              </div>
            </div>
          ) : null}

          {leaderboard.state === State.Live &&
          leaderboard.type === LbType.RankBased.toString() &&
          leaderboard.player_rank > 0 ? (
            <div>
              <div className={styles.key}>Current Rank</div>
              <div className={styles.value}>
                {`${leaderboard.player_rank} / ${
                  leaderboard.total_participants || '0'
                }`}
              </div>
            </div>
          ) : null}

          {leaderboard.state === State.Live &&
          leaderboard.type === LbType.GoalBased.toString() &&
          !!leaderboard.player_score ? (
            <div>
              <div className={styles.key}>Current Goal</div>
              <div className={styles.value}>
                {currentGoal > leaderboard.prize_distribution.goal_based.length
                  ? `Completed`
                  : `${currentGoal} / ${leaderboard.prize_distribution.goal_based.length}`}
              </div>
            </div>
          ) : null}

          {leaderboard.state === State.ComputingResult &&
          leaderboard.type === LbType.GoalBased.toString() ? (
            <div>
              <div className={styles.key}>Current Goal</div>
              <div className={styles.value}>-</div>
            </div>
          ) : null}

          {leaderboard.state === State.ResultDeclared &&
          leaderboard.type === LbType.RankBased.toString() ? (
            <div>
              <div className={styles.key}>Final Rank</div>
              <div className={styles.value}>
                {`${
                  !leaderboard.player_rank
                    ? 'NA'
                    : `${leaderboard.player_rank} / ${
                        leaderboard.total_participants || '0'
                      }`
                }`}
              </div>
            </div>
          ) : null}

          {leaderboard.state === State.ResultDeclared &&
          leaderboard.type === LbType.GoalBased.toString() ? (
            <div>
              <div className={styles.key}>Goal Complete</div>
              <div className={styles.value}>
                {`${
                  !leaderboard.player_score
                    ? 'NA'
                    : currentGoal >
                      leaderboard.prize_distribution.goal_based.length
                    ? `${leaderboard.prize_distribution.goal_based.length} / ${leaderboard.prize_distribution.goal_based.length}`
                    : `${currentGoal - 1} / ${
                        leaderboard.prize_distribution.goal_based.length
                      }`
                }`}
              </div>
            </div>
          ) : null}
        </div>
      </div>
    </div>
  )
}

export default LeaderboardCard
