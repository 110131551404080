export interface FilterOptionsType {
  options: FilterValues[]
}

export interface FilterPillsType {
  pills: FilterValues[]
}

export interface FilterSliderType {
  sliderMin: number
  sliderMax: number
}

export enum FilterType {
  options = 'options',
  pills = 'pills',
  slider = 'slider',
}

export interface Filter {
  header: string
  key: string
  type: FilterType
  typeData: FilterOptionsType | FilterPillsType | FilterSliderType
  position: number
  hidden: boolean
  showConditions?: any
  forceHide?: boolean
}

export enum FilterKeys {
  entryType = 'entryType',
  games = 'games',
  entryPrice = 'entryPrice',
  bigBlind = 'bigBlind',
  pointRate = 'pointRate',
  type = 'type',
  duration = 'duration',
}

export enum FilterValues {
  // entryType
  Free = 'Free',
  Paid = 'Paid',

  //games
  Poker = 'Poker',
  Rummy = 'Rummy',
  Carrom = 'Carrom',
  Pool = 'Pool',

  //type
  RankBased = 'Rank Based',
  GoalBased = 'Goal Based',

  //duration
  LessThan24hrs = 'Less Than 24 hrs',
  Day = 'Day',
  GreaterThan24hrs = 'Greater Than 24 hrs',
}
