import { datadogRum } from '@datadog/browser-rum'
import { getPlayerId } from '../auth-token'

const initializeDatadogRUM = () => {
  let env = process.env.REACT_APP_ENV
  if (process.env.REACT_APP_ENV === 'production') {
    env = 'prod'
  }

  datadogRum.init({
    applicationId: process.env.REACT_APP_DATADOG_APPLICATION_ID,
    clientToken: process.env.REACT_APP_DATADOG_CLIENT_TOKEN,
    site: process.env.REACT_APP_DATADOG_SITE,
    service: process.env.REACT_APP_DATADOG_SERVICE,
    env,
    sampleRate: 5,
    trackInteractions: true,
    defaultPrivacyLevel: 'allow',
    allowedTracingOrigins: [process.env.REACT_APP_MAPI_HOST_URI],
  })

  datadogRum.addRumGlobalContext('playerId', getPlayerId())
  datadogRum.startSessionReplayRecording()
}

export default initializeDatadogRUM
