import {
  Filter,
  FilterType,
  FilterKeys,
  FilterValues,
} from '../../types/filters'
import { ViewProp } from '../../containers/LeaderboardsList/SortContext'

export const filtersConfig: Filter[] = [
  {
    header: 'Entry Type',
    key: FilterKeys.entryType,
    type: FilterType.options,
    position: 1,
    typeData: {
      options: [FilterValues.Free, FilterValues.Paid],
    },
    hidden: false,
  },
  {
    header: 'Games',
    key: FilterKeys.games,
    type: FilterType.pills,
    position: 2,
    typeData: {
      pills: [
        FilterValues.Poker,
        FilterValues.Rummy,
        FilterValues.Carrom,
        FilterValues.Pool,
      ],
    },
    hidden: true,
    forceHide: true,
  },
  {
    header: 'Entry Price',
    key: FilterKeys.entryPrice,
    type: FilterType.slider,
    position: 3,
    typeData: {
      sliderMin: 0,
      sliderMax: 5000,
    },
    hidden: true,
    showConditions: { [FilterKeys.entryType]: FilterValues.Paid },
  },
  {
    header: 'Big Blind',
    key: FilterKeys.bigBlind,
    type: FilterType.slider,
    position: 4,
    typeData: {
      sliderMin: 0,
      sliderMax: 200,
    },
    hidden: true,
    showConditions: { [FilterKeys.games]: FilterValues.Poker },
  },
  {
    header: 'Point Rate',
    key: FilterKeys.pointRate,
    type: FilterType.slider,
    position: 4,
    typeData: {
      sliderMin: 0,
      sliderMax: 50,
    },
    hidden: true,
    showConditions: { [FilterKeys.games]: 'Not Poker' },
  },
  {
    header: 'Type',
    key: FilterKeys.type,
    type: FilterType.options,
    position: 5,
    typeData: {
      options: [FilterValues.RankBased, FilterValues.GoalBased],
    },
    hidden: false,
  },
  {
    header: 'Duration',
    key: FilterKeys.duration,
    type: FilterType.options,
    position: 6,
    typeData: {
      options: [
        FilterValues.LessThan24hrs,
        FilterValues.Day,
        FilterValues.GreaterThan24hrs,
      ],
    },
    hidden: false,
  },
]

const getFilters = (f: Filter[]) => {
  return f.sort((a, b) => a.position - b.position)
}

export const GameFilterViewMap: ViewProp[] = [
  {
    id: 'cards',
    header: 'Cards',
    pills: [
      {
        id: FilterValues.Poker,
        value: FilterValues.Poker,
      },
      {
        id: FilterValues.Rummy,
        value: FilterValues.Rummy,
      },
    ],
  },
  {
    id: 'casual',
    header: 'Casual',
    pills: [
      {
        id: FilterValues.Pool,
        value: FilterValues.Pool,
      },
      {
        id: FilterValues.Carrom,
        value: FilterValues.Carrom,
      },
    ],
  },
]

let filters = getFilters(filtersConfig)
export default filters
