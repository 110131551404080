import React, { useEffect } from 'react'
import dayjs from 'dayjs'

import styles from './PlayerSummary.module.scss'
import { LeaderboardDetails } from '../../types/leaderboard-details'
import Empty from '../Empty/Empty'
import { EntrySummary } from '../../types/leaderboard-entry'

interface Props {
  lb: LeaderboardDetails
  summary: EntrySummary[]
}

const PlayerSummary: React.FC<Props> = ({ lb, summary }) => {
  useEffect(() => {
    try {
      window.analytics.track('ScoreSummaryPageViewed', {
        leaderboard_id: lb.leaderboard_id,
        details_on_the_screen: summary,
        source_screen: 'Leaderboard Summary',
      })
    } catch (error) {
      console.error(error)
    }
    try {
      window.rudderanalytics.track('ScoreSummaryPageViewed', {
        leaderboard_id: lb.leaderboard_id,
        details_on_the_screen: summary,
        source_screen: 'Leaderboard Summary',
      })
    } catch (error) {
      console.error(error)
    }
  })

  return (
    <div id={styles.PlayerSummary}>
      {summary?.length > 0 ? (
        <>
          <div>
            <span>Total Score </span>
            <span className={styles.b}>
              {lb.player_score
                ? lb.player_score.toFixed(2).replace('.00', '')
                : 0}
            </span>
          </div>

          {summary
            .filter(s => s.timestamp)
            .map(s => {
              // meta might conatin invalid json
              let meta: any
              try {
                meta = JSON.parse(s.meta)
              } catch (error) {
                console.error(error)
              }

              return (
                <React.Fragment key={s.timestamp}>
                  <div className={styles.separator} />
                  <div className={styles.score}>
                    <div>
                      <div>
                        {meta?.PokerHand} • BB ₹
                        {meta && meta['Tournament.TournamentConfig.BigBlind']}
                      </div>
                      <div className={styles.time}>
                        {dayjs(parseInt(s.timestamp, 10) * 1000).format('lll')}
                      </div>
                    </div>
                    <div>
                      {s.score ? s.score.toFixed(2).replace('.00', '') : 0}
                    </div>
                  </div>
                </React.Fragment>
              )
            })}
        </>
      ) : (
        <Empty message="Sorry! You did not join the leaderboard" />
      )}

      {/* <div className={styles.more}>Show More →</div> */}
    </div>
  )
}

export default PlayerSummary
