import React, { createContext, ReactNode, useContext, useState } from 'react'
import dayjs, { Dayjs } from 'dayjs'

interface Props {
  children: ReactNode
}

interface DateContextType {
  date: Dayjs
  setDate(date: Dayjs): void
}

const DateContext = createContext<DateContextType | undefined>(undefined)

const DateProvider = ({ children }: Props): JSX.Element => {
  const [date, setDate] = useState<Dayjs>(dayjs().startOf('day'))

  return (
    <DateContext.Provider value={{ date, setDate }}>
      {children}
    </DateContext.Provider>
  )
}

export default DateProvider
export const useDate = (): DateContextType | undefined =>
  useContext(DateContext)
