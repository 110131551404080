import React from 'react'
import styles from './Pill.module.scss'

interface Props {
  style?: React.CSSProperties
  onClick(e: React.MouseEvent<HTMLDivElement, MouseEvent>): void
}

const Pill: React.FC<Props> = ({ children, style, onClick }) => {
  return (
    <div style={style} className={styles.Pill} onClick={onClick}>
      {children}
    </div>
  )
}

export default Pill
