import React from 'react'
import { Router } from '@reach/router'
import './App.scss'
import LeaderboardsList from './containers/LeaderboardsList/LeaderboardsList'
import LeaderboardDetails from './containers/LeaderboardDetails/LeaderboardDetails'
import MyLeaderboards from './containers/LeaderboardsList/MyLeaderboards'
import PropertyDetails from './containers/Properties/PropertyDetails'

const App: React.FC = () => {
  return (
    <div className="App">
      <Router>
        {/* using filterParams hack instead of queryParams structure since android uses queryParams structure for it's deeplink */}
        {/* and it will parse the query params sent to it and won't send us the queryParams data */}
        <LeaderboardsList path="/*filterParams" />
        <LeaderboardDetails path="/leaderboard-details/:leaderboardId" />
        {/* need this hack since the success deeplink for deposit coincides with the query params of website */}
        <LeaderboardDetails path="/leaderboard-details/:leaderboardId/purchaseCard" />
        <PropertyDetails path="/property-details/:propertyId" />
        <MyLeaderboards path="/my-leaderboards" />
      </Router>
    </div>
  )
}

export default App
