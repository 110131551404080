import React from 'react'
import styles from './CurrentRank.module.scss'
import { LeaderboardDetails } from '../../types/leaderboard-details'
import { State } from '../../types/leaderboard'
import { LeaderboardEntry } from '../../types/leaderboard-entry'
import LockedIcon from '../../assets/images/locked.png'
import CurrentIcon from '../../assets/images/current.png'
import { getPlayerId } from '../../auth-token'
import {
  MetricFieldTournamentConfig,
  LeaderboardType,
} from '../../helpers/constants'

interface Props {
  lbDetails: LeaderboardDetails
  entries: LeaderboardEntry[]
}

const CurrentRank: React.FC<Props> = ({ lbDetails, entries }) => {
  const progress = Math.min(
    ((lbDetails.player_score || 0) * 100) /
      lbDetails.prize_distribution.rank_based[
        lbDetails.prize_distribution.rank_based.length - 1
      ].qualification_score,
    100
  )

  if (lbDetails.state === State.ResultDeclared && !lbDetails.player_rank) {
    return null
  }

  const getCardIcon = (lbState: string): string => {
    switch (lbState) {
      case State.Live || State.ResultDeclared:
        return CurrentIcon
      case State.Draft || State.Open || State.ComputingResult:
        return LockedIcon
      default:
        return CurrentIcon
    }
  }

  // find logged in player's entry
  const myPlayerId = getPlayerId()
  const myEntry = entries.find(e => e.player.player_id === myPlayerId)

  return (
    <div
      id={styles.CurrentRank}
      style={{
        background:
          (lbDetails.state === State.ResultDeclared &&
            lbDetails.player_rank >
              lbDetails.prize_distribution.rank_based[
                lbDetails.prize_distribution.rank_based.length - 1
              ].end_rank) ||
          (myEntry && myEntry.prize === 0)
            ? '#FFEFEE'
            : '#f4f4ff',
      }}
    >
      <div className={styles.titleDiv}>
        <div className={styles.pageTitle}>
          {LeaderboardType[lbDetails.type]} Leaderboard
        </div>
        <img
          src={getCardIcon(lbDetails.state)}
          className={styles.cardIcon}
          alt="state icon"
        />
      </div>

      {lbDetails.type === 'Paid' && lbDetails.state === State.Open ? (
        <div className={styles.currentRank}>
          Slots: <span>12/1000</span>
        </div>
      ) : null}

      {lbDetails.state === State.Live ? (
        <div className={styles.currentRank}>
          Current Rank:{' '}
          <span>
            {lbDetails.player_rank > 0
              ? `${lbDetails.player_rank}/${
                  lbDetails.total_participants || '0'
                }`
              : 'NA'}
          </span>
        </div>
      ) : null}

      {lbDetails.state === State.ComputingResult ? (
        <div className={styles.currentRank}>Final Rank: -</div>
      ) : null}

      {lbDetails.state === State.ResultDeclared && (
        <>
          {lbDetails.player_rank >
          lbDetails.prize_distribution.rank_based[
            lbDetails.prize_distribution.rank_based.length - 1
          ].end_rank ? (
            <div className={styles.currentRank}>Leaderboard is over</div>
          ) : (
            <div className={styles.currentRank}>
              Final Rank: {lbDetails.player_rank} /{' '}
              {lbDetails.total_participants || '0'}
            </div>
          )}
        </>
      )}

      {lbDetails.state === State.Live ? (
        <div className={styles.currentScore}>
          Your Current Score is{' '}
          <span>
            {lbDetails.player_score?.toFixed(2).replace('.00', '') || '0'}
          </span>
        </div>
      ) : null}

      {lbDetails.state === State.ResultDeclared ||
      lbDetails.state === State.ComputingResult ? (
        <div className={styles.currentScore}>
          Your Final Score is{' '}
          <span>
            {!!lbDetails.player_score
              ? lbDetails.player_score?.toFixed(2).replace('.00', '')
              : '0'}
          </span>
        </div>
      ) : null}

      <div
        className={styles.progress}
        style={{
          background: `linear-gradient(90deg, #276ef1 ${progress}%, #817272 ${progress}%)`,
        }}
      />
      {lbDetails.state === State.Live ? (
        <>
          {(lbDetails.player_score || 0) <
          lbDetails.prize_distribution.rank_based[
            lbDetails.prize_distribution.rank_based.length - 1
          ].qualification_score ? (
            <div>
              Score minimum of{' '}
              {
                lbDetails.prize_distribution.rank_based[
                  lbDetails.prize_distribution.rank_based.length - 1
                ].qualification_score
              }{' '}
              in tournaments
              {lbDetails.filters.map(f => {
                if (
                  f.metric_field.toString() === 'Bigblind' ||
                  f.metric_field.toString() === 'PointRate'
                ) {
                  return f.value_range.number_min === f.value_range.number_max
                    ? ` with ${
                        MetricFieldTournamentConfig[f.metric_field.toString()]
                      } more than ₹${f.value_range.number_min}`
                    : ` between ${
                        MetricFieldTournamentConfig[f.metric_field.toString()]
                      }s ₹${f.value_range.number_min} - ₹${
                        f.value_range.number_max
                      }`
                }

                return ''
              })}
              <span> to qualify for any cash prize</span>
            </div>
          ) : null}

          {lbDetails.player_rank === 1 &&
          lbDetails.player_score >=
            lbDetails.prize_distribution.rank_based[
              lbDetails.prize_distribution.rank_based.length - 1
            ].qualification_score ? (
            <div>Keep playing to maintain your rank</div>
          ) : null}

          {lbDetails.player_score >=
            lbDetails.prize_distribution.rank_based[
              lbDetails.prize_distribution.rank_based.length - 1
            ].qualification_score && lbDetails.player_rank !== 1 ? (
            <div>
              Score minimum of{' '}
              {entries[0]?.score
                ? 1 + parseInt(`${entries[0].score}`, 10) || 0
                : lbDetails.prize_distribution.rank_based[
                    lbDetails.prize_distribution.rank_based.length - 1
                  ].qualification_score}{' '}
              for rank 1 to win ₹
              {lbDetails.prize_distribution.rank_based[0].prize.winning_amount}
            </div>
          ) : null}
        </>
      ) : null}

      {lbDetails.state === State.ResultDeclared &&
      (lbDetails.player_rank || Infinity) >
        lbDetails.prize_distribution.rank_based[
          lbDetails.prize_distribution.rank_based.length - 1
        ].end_rank &&
      !myEntry ? (
        <div>You lost. Try another Leaderboard</div>
      ) : null}

      {lbDetails.state === State.ResultDeclared && myEntry ? (
        <div>{myEntry.comment}</div>
      ) : null}

      {lbDetails.state === State.ResultDeclared &&
      myEntry &&
      myEntry.prize > 0 ? (
        <div>Join new Leaderboard to earn more.</div>
      ) : null}
    </div>
  )
}

export default CurrentRank
