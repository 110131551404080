import React, { useRef, useEffect, useState } from 'react'

import styles from './PrizeDistributionTable.module.scss'
import { LeaderboardDetails } from '../../types/leaderboard-details'
import CollapsebaleSection from '../CollapseableSection/CollapseableSection'
import { DefaultWalletType, LbType, WalletTypes } from '../../types/leaderboard'
import RewardDetails from '../RewardDetails/RewardDetails'

interface Props {
  lbDetails: LeaderboardDetails
  setRef(r: React.RefObject<HTMLInputElement>): void
}

const PrizeDistributionTable: React.FC<Props> = ({ lbDetails, setRef }) => {
  const ref = useRef(null)

  const [rewardDetails, setRewardDetails] = useState(null)
  const [showPrizeDistribution, setShowPrizeDistribution] = useState(true)

  useEffect(() => {
    setRef(ref)
  }, [ref, setRef])

  const getTableBasedOnType = () => {
    if (lbDetails.type === LbType.RankBased.toString()) {
      return (
        <>
          {showPrizeDistribution && (
            <table className={styles.table}>
              <thead>
                <tr>
                  <th>Rank</th>
                  <th className={styles.right}>Min Score</th>
                  <th className={styles.right}>Prize</th>
                  <th className={styles.right}>Wallet</th>
                </tr>
              </thead>
              <tbody>
                {lbDetails.prize_distribution.rank_based.map(d => {
                  return (
                    <tr
                      key={d.start_rank}
                      onClick={(): void => {
                        if (d.prize.prize_type === 'STAGGERED') {
                          setShowPrizeDistribution(false)
                          setRewardDetails(d.prize.task_ui_info)
                        }
                      }}
                      onKeyDown={(e): void => {
                        if (e.key === 'Enter') {
                          if (d.prize.prize_type === 'STAGGERED') {
                            setShowPrizeDistribution(false)
                            setRewardDetails(d.prize.task_ui_info)
                          }
                        }
                      }}
                    >
                      <td>
                        Rank{' '}
                        {d.start_rank !== d.end_rank && !!d.end_rank
                          ? `${d.start_rank} - ${d.end_rank}`
                          : d.start_rank}
                      </td>
                      <td className={`${styles.right} ${styles.black}`}>
                        {d.qualification_score || 0}
                      </td>
                      <td className={`${styles.right} ${styles.black}`}>
                        {d.prize.display_value
                          ? d.prize.display_value
                          : `₹${d.prize.winning_amount}`}
                      </td>
                      <td className={`${styles.right} ${styles.black}`}>
                        {d.prize.wallet_type
                          ? WalletTypes[d.prize.wallet_type]
                          : DefaultWalletType}
                      </td>
                      {d.prize.prize_type === 'STAGGERED' && (
                        <td role="presentation" className={`${styles.black}`}>
                          &gt;
                        </td>
                      )}
                    </tr>
                  )
                })}
              </tbody>
            </table>
          )}
          {!showPrizeDistribution && (
            <RewardDetails
              rewardDetails={rewardDetails}
              setShowPrizeDistribution={setShowPrizeDistribution}
            />
          )}
        </>
      )
    }

    if (lbDetails.type === LbType.GoalBased.toString()) {
      const totalPrize = lbDetails?.prize_distribution.goal_based.reduce(
        (sum: number, c) => {
          return sum + c.prize.winning_amount * 1
        },
        0
      )
      return (
        <table className={styles.table}>
          <thead>
            <tr>
              <th>Goal</th>
              <th className={styles.right}>Score</th>
              <th className={styles.right}>Prize</th>
              <th className={styles.right}>Wallet</th>
            </tr>
          </thead>
          <tbody>
            {lbDetails.prize_distribution.goal_based.map(g => {
              return (
                <tr key={g.goal_name}>
                  <td>{g.goal_name}</td>
                  <td className={`${styles.right} ${styles.black}`}>
                    {g.winning_score}
                  </td>
                  <td className={`${styles.right} ${styles.black}`}>
                    {g.prize.display_value
                      ? g.prize.display_value
                      : `₹${g.prize.winning_amount}`}
                  </td>
                  <td className={`${styles.right} ${styles.black}`}>
                    {g.prize.wallet_type
                      ? WalletTypes[g.prize.wallet_type]
                      : DefaultWalletType}
                  </td>
                </tr>
              )
            })}
            <tr>
              <td className={styles.bold}>Total</td>
              <td className={styles.right} />
              <td
                className={`${styles.right} ${styles.black}`}
              >{`₹${totalPrize}`}</td>
              <td className={styles.right} />
            </tr>
          </tbody>
        </table>
      )
    }

    return null
  }

  const sendPrizeDistributionClickedEvent = (): void => {
    try {
      window.analytics.track('PrizeDistributionClicked', {
        leaderboard_id: lbDetails.leaderboard_id,
        source_screen: 'My leaderboard detail',
      })
    } catch (error) {
      console.error(error)
    }
    try {
      window.rudderanalytics.track('PrizeDistributionClicked', {
        leaderboard_id: lbDetails.leaderboard_id,
        source_screen: 'My leaderboard detail',
      })
    } catch (error) {
      console.error(error)
    }
  }

  return (
    <div id={styles.PrizeDistributionTable} ref={ref}>
      <CollapsebaleSection
        title="Prize Distribution Table"
        onHeaderClick={sendPrizeDistributionClickedEvent}
      >
        {getTableBasedOnType()}
      </CollapsebaleSection>
    </div>
  )
}

export default PrizeDistributionTable
