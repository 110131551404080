// @ts-ignore
import Carousel from 'infinite-react-carousel'
import React from 'react'
import styles from './Banners.module.scss'

interface Props {
  banners: Banner[]
}

interface Banner {
  deep_link: string
  image_url: string
}

const Banners: React.FC<Props> = ({ banners }) => {
  return (
    <Carousel
      dots
      arrows={false}
      adaptiveHeight
      className={styles['infinite-carousel']}
      duration={500}
    >
      {banners.map(b => (
        <a
          className={styles['banner-carousel']}
          href={b.deep_link}
          key={b.deep_link}
        >
          <img
            className={styles['bannerImage']}
            src={`https://storage.googleapis.com/mega-prod-assets.getmega.com/${b.image_url}`}
            alt="contestant"
          />
        </a>
      ))}
    </Carousel>
  )
}

export default Banners
