import React, { useState } from 'react'
import styles from './SortOrFilterView.module.scss'
import SortDown from '../../assets/images/arrow-down.png'
import SortUp from '../../assets/images/arrow-up.png'
import { ViewProp } from '../../containers/LeaderboardsList/SortContext'
import { Leaderboard } from '../../types/leaderboard'
import { getFilteredLbs } from '../../helpers/filters'

interface Props {
  viewMap: ViewProp[]
  selectedIds: string[]
  showResults: Function
  multiSelect: boolean
  showClearAll?: boolean
  clearAll?: Function
  isFilter?: boolean
  leaderboards: Leaderboard[]
}

const SortOrFilterView: React.FC<Props> = ({
  viewMap,
  selectedIds,
  showResults,
  multiSelect,
  showClearAll,
  clearAll,
  isFilter,
  leaderboards,
}) => {
  const [selectedMapIds, setSelectedMapIds] = useState(
    selectedIds instanceof Array ? selectedIds : []
  )
  const [buttonText, setButtonText] = useState('Show Results')

  return (
    <div className={styles.container}>
      {viewMap.map(v => {
        return (
          <div key={v.id} className={styles.sortDiv}>
            <p className={styles.header}>{v.header}</p>
            <div className={styles.pillsDiv}>
              {v.pills.map(p => {
                let pillStyles = styles.pill
                if (selectedMapIds.includes(p.id)) {
                  pillStyles = `${styles.pill} ${styles.active}`
                }

                return (
                  <p
                    key={p.id}
                    className={pillStyles}
                    onClick={() => {
                      let mapIds = []
                      if (multiSelect) {
                        if (selectedMapIds.includes(p.id)) {
                          mapIds = selectedMapIds.filter(s => s !== p.id)
                        } else {
                          mapIds = [...selectedMapIds, p.id]
                        }
                      } else {
                        mapIds = [p.id]
                      }

                      setSelectedMapIds(mapIds)

                      if (isFilter) {
                        let allLeaderboardsCount = leaderboards.length
                        if (allLeaderboardsCount > 0) {
                          let filters =
                            JSON.parse(
                              window.sessionStorage.getItem('filters')
                            ) || {}
                          if (mapIds.length > 0) {
                            filters = { ...filters, games: mapIds }
                            const flbs = getFilteredLbs(leaderboards, filters)
                            setButtonText(
                              `Show ${flbs.length} / ${allLeaderboardsCount} Results`
                            )
                            return
                          }
                        }
                      }
                      setButtonText(`Show Results`)
                    }}
                  >
                    {p.value}
                    {p.showIcon && (
                      <img
                        src={p.type === 'DESC' ? SortDown : SortUp}
                        className={styles.sort}
                        alt="sortArrow"
                      />
                    )}
                  </p>
                )
              })}
            </div>
            <div className={styles.hr} />
          </div>
        )
      })}
      <div
        className={styles.buttonDiv}
        style={
          showClearAll
            ? { justifyContent: 'space-between' }
            : { justifyContent: 'flex-end' }
        }
      >
        {showClearAll && (
          <button
            className={styles.clearAllButton}
            onClick={() => {
              clearAll()
            }}
          >
            Clear All
          </button>
        )}

        <button
          className={styles.resultsButton}
          onClick={() => {
            showResults(selectedMapIds)
          }}
        >
          {buttonText}
        </button>
      </div>
    </div>
  )
}

export default SortOrFilterView
