import React, { useEffect, useState, useCallback } from 'react'
import styles from './PropertyDetails.module.scss'
import { PropertyDetails as PropertyDetailsType } from '../../types/property-details'
import { RouteComponentProps } from '@reach/router'
import { State, Leaderboard } from '../../types/leaderboard'
import Countdown from '../LeaderboardDetails/Countdown'
import { getPropertyDetails } from './api'
import LeaderboardCard from '../../components/LeaderboardCard/LeaderboardCard'
import { useDate } from '../LeaderboardsList/DateContext'
import WeekCalendar from '../../components/WeekCalendar/WeekCalendar'
import { getLeaderboards } from '../LeaderboardsList/api'
import { Dayjs } from 'dayjs'
import DetailsCard from './DetailsCard/DetailsCard'
import DatewisePrizeDistribution from './DatewisePrizeDistribution/DatewisePrizeDistribution'
import CollapsebaleSection from '../../components/CollapseableSection/CollapseableSection'
import DailyPrizeDistribution from './DailyPrizeDistribution/DailyPrizeDistribution'

type Props = RouteComponentProps<{
  propertyId: string
}>

const PropertyDetails: React.FC<Props> = props => {
  const { propertyId } = props
  const [loading, setLoading] = useState(true)
  const [, setError] = useState('')
  const [propertyDetails, setPropertyDetails] = useState<
    PropertyDetailsType | undefined
  >()
  const { date, setDate } = useDate()!
  const [leaderboards, setLeaderboards] = useState<Leaderboard[]>([])

  const fetchPropertyDetails = useCallback(
    async (id: string): Promise<void> => {
      setLoading(true)
      const { details, error: err } = await getPropertyDetails(id)
      setPropertyDetails(details)
      setError(err)
      setLoading(false)
    },
    []
  )

  const fetchLeaderboards = async (d: Dayjs): Promise<Leaderboard[]> => {
    const { leaderboards: lbs } = await getLeaderboards(d.format(), propertyId)
    setLeaderboards(lbs)

    return lbs
  }

  const fetchLbs = useCallback(async (d: Dayjs): Promise<void> => {
    await Promise.all([fetchLeaderboards(d)]).then(values => {
      return values
    })
  }, [])

  useEffect(() => {
    if (propertyId) {
      fetchLbs(date)
    }
  }, [propertyId, date, fetchLbs])

  useEffect(() => {
    if (propertyId) {
      fetchPropertyDetails(propertyId)
    }
  }, [propertyId, fetchPropertyDetails])

  return (
    <div id={styles.PropertyDetails}>
      {loading ? (
        <div className={styles.loading}>Loading Property details ...</div>
      ) : (
        <div id={styles.LeaderboardDetails}>
          <div className={styles.pageTitle}>
            {propertyDetails?.external_name}
          </div>

          <div className={styles.header}>
            <div className={styles.contestDescription}>
              <span>{propertyDetails?.description}</span>
            </div>
            <div className={styles.timeRemaining}>
              {propertyDetails?.state === State.Live ? (
                <span>Time Remaining</span>
              ) : null}
            </div>
            <div className={styles.cta}>
              <div className={styles.time}>
                {propertyDetails?.state === State.Visible ? (
                  <Countdown
                    eventTime={new Date(propertyDetails.schedule.start_time)}
                  />
                ) : null}
                {propertyDetails?.state === State.Live ? (
                  <Countdown
                    eventTime={new Date(propertyDetails.schedule.end_time)}
                  />
                ) : null}
              </div>
            </div>
          </div>

          <DetailsCard propertyDetails={propertyDetails} />
          <DailyPrizeDistribution propertyDetails={propertyDetails} />
          <DatewisePrizeDistribution propertyDetails={propertyDetails} />

          <div style={{ padding: 16 }}>
            <CollapsebaleSection title="Leaderboards">
              <div className={styles.weekCalendar}>
                <WeekCalendar
                  date={date}
                  onDayClick={(d): void => {
                    setDate(d)
                  }}
                />
              </div>

              <div className={styles.leaderboards}>
                {[State.Live, State.Open, State.ResultDeclared].map(state => {
                  return (
                    <React.Fragment key={state}>
                      {leaderboards
                        .filter(lb => lb.state === state)
                        .map(lb => {
                          return (
                            <React.Fragment key={lb.leaderboard_id}>
                              <LeaderboardCard leaderboard={lb} date={date} />
                              <div style={{ height: '12px' }} />
                            </React.Fragment>
                          )
                        })}
                    </React.Fragment>
                  )
                })}
              </div>
            </CollapsebaleSection>
          </div>
        </div>
      )}
    </div>
  )
}

export default PropertyDetails
