import React, { useState } from 'react'
import styles from './LeaderboardAndSummary.module.scss'
import PrizeDistributionTable from '../../components/PrizeDistributionTable/PrizeDistributionTable'
import Leaderboard from '../../components/Leaderboard/Leaderboard'
import PlayerSummary from '../../components/PlayerSummary/PlayerSummary'
import { LeaderboardDetails } from '../../types/leaderboard-details'
import { State } from '../../types/leaderboard'
import { LeaderboardEntry, EntrySummary } from '../../types/leaderboard-entry'

const tabs = ['Leaderboard', 'Your Summary']

interface Props {
  lbDetails: LeaderboardDetails
  entries: LeaderboardEntry[]
  summary: EntrySummary[]
}

const LeaderboardAndSummary: React.FC<Props> = ({
  lbDetails,
  entries,
  summary,
}) => {
  const [selectedTab, setSelectedTab] = useState(0)

  const [pdRef, setPdRef] = useState<React.RefObject<HTMLDivElement> | null>(
    null
  )

  return (
    <div id={styles.LeaderboardAndSummary}>
      {lbDetails.state !== State.Draft && lbDetails.state !== State.Open ? (
        <div className={styles.tabs}>
          {tabs.map((t, i) => {
            return (
              <div
                key={t}
                role="button"
                tabIndex={0}
                onClick={(): void => {
                  setSelectedTab(i)
                }}
                onKeyDown={(e): void => {
                  if (e.key === 'Enter') setSelectedTab(i)
                }}
                className={selectedTab === i ? styles.selected : ''}
              >
                {t}
              </div>
            )
          })}
        </div>
      ) : null}

      {selectedTab === 0 ? (
        <div>
          {lbDetails.state !== State.ResultDeclared && (
            <PrizeDistributionTable lbDetails={lbDetails} setRef={setPdRef} />
          )}
          {lbDetails.state !== State.Draft && lbDetails.state !== State.Open ? (
            <Leaderboard lb={lbDetails} entries={entries} pdRef={pdRef} />
          ) : null}
        </div>
      ) : null}

      {selectedTab === 1 ? (
        <div style={{ padding: '0 16px' }}>
          <PlayerSummary lb={lbDetails} summary={summary} />
        </div>
      ) : null}
    </div>
  )
}

export default LeaderboardAndSummary
