// to run it in browser, you can replace this with correct token
const dummyToken = 'dev|xdpXibhsugfjbvJ5EPcr6QIiwbC3'
const dummyPlayerId = 'xdpXibhsugfjbvJ5EPcr6QIiwbC3'

const getAuthData = (): string =>
  // this will be available in web view
  (window as any).MegaInterface && (window as any).MegaInterface.getAuthData()
const getAuthDataJSON = (): Record<string, string> =>
  (window as any).MegaInterface?.getAuthDataJSON()

const getAuthToken = (): string => {
  try {
    if (typeof window !== `undefined`) {
      const authData = getAuthData()

      let authToken
      if (authData) {
        authToken = authData.split(',')[0].replace('{Authorization=', '')
      }

      if (authToken) return authToken
      return dummyToken
    }
  } catch (error) {
    console.error(error)
    return ''
  }

  return ''
}

export const getPlayerId = (): string => {
  try {
    if (typeof window !== `undefined`) {
      const authData = getAuthData()

      let playerId
      if (authData) {
        playerId = authData
          .split(',')[5]
          .replace(' x-appsflyer-customer-id=', '')
      }

      if (playerId) return playerId
      return dummyPlayerId
    }
  } catch (error) {
    console.error(error)
  }

  return ''
}

export const getAppFlavour = (): string => {
  try {
    if (typeof window !== `undefined`) {
      const authData = getAuthDataJSON()

      let appFlavour = ''
      if (authData) {
        appFlavour = authData['x-app-flavor']
      }

      return appFlavour
    }
  } catch (error) {
    console.error(error)
  }

  return ''
}

export default getAuthToken
