export default {
  previous_winners: [
    {
      profile_pic_url:
        'https://img1.getmegacdn.com/image/facebook/3072648992856461.webp?tr=w-300.00,h-300.00,q-80,di-default_user_dp.png',
      name: 'Bhavin Nandani',
      prize: 'Rank 1 - BB40',
      amount: '3000',
      winnings: '51373',
    },
    {
      profile_pic_url:
        'https://img1.getmegacdn.com/image/facebook/2774909075961277.webp?tr=w-300.00,h-300.00,q-80,di-default_user_dp.png',
      name: 'Kunal Kashyap',
      prize: 'Rank 1 - BB100',
      amount: '8000',
      winnings: '143860',
    },
  ],
  testimonials: [
    {
      rank: '1',
      name: 'Manish Rajput',
      profile_url:
        'https://img1.getmegacdn.com/image/facebook/3707569999313046.webp?tr=w-300.00,h-300.00,q-80,di-default_user_dp.png',
      text: 'The best poker app I have found so far… it is so easy to deposit & withdraw. There are so many types of contests... I don’t get bored at all',
    },
    {
      rank: '1',
      name: 'Khush Taneja',
      profile_url:
        'https://img1.getmegacdn.com/image/facebook/638884923387603.webp?tr=w-300.00,h-300.00,q-80,di-default_user_dp.png',
      text: "I have tried many games but this is easiest to play & make money  here's  everyone gets mega",
    },
  ],
  banners: [
    {
      deep_link: 'https://www.getmega.com/wallets?initiateDeposit=true',
      image_url: 'v1/assets/websites/moonshot/weekly/banners/3.jpg',
    },
  ],
}
