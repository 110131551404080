import React, { useEffect, useState } from 'react'

import styles from './DatewisePrizeDistribution.module.scss'
import CollapsebaleSection from '../../../components/CollapseableSection/CollapseableSection'
import { LBPrizes, PropertyDetails } from '../../../types/property-details'
import dayjs from 'dayjs'

interface Props {
  propertyDetails: PropertyDetails
}

const DatewisePrizeDistribution: React.FC<Props> = ({ propertyDetails }) => {
  const [dateKeys, setDateKeys] = useState([])
  const [dateValues, setDateValues] = useState<LBPrizes[]>([])
  const [selectedKey, setSelectedKey] = useState()

  useEffect(() => {
    let prizes = propertyDetails.prize_distribution.datewise_prizes
    let dKeys = prizes.map(p => {
      return {
        key: p.date,
        value: dayjs(p.date).format('D MMM'),
      }
    })
    setDateKeys(dKeys)
    if (prizes[0].LB_prizes) {
      setDateValues(prizes[0].LB_prizes)
    }
    setSelectedKey(prizes[0].date)
  }, [propertyDetails])

  return (
    <div id={styles.DatewisePrizeDistribution}>
      <CollapsebaleSection
        title="Datewise Prize Distribution"
        contentClassName={styles.section}
      >
        <p className={styles.headerText}>
          Choose <span className={styles.headerBold}>a specific date</span> to
          see prize distribution
        </p>
        <div className={styles.pillsContainer}>
          {dateKeys.map(d => {
            let extraStyles = d.key === selectedKey ? styles.filterSelected : ''
            return (
              <p
                className={`${extraStyles} ${styles.pill}`}
                onClick={() => {
                  let prop =
                    propertyDetails.prize_distribution.datewise_prizes.find(
                      p => p.date === d.key
                    )
                  setDateValues(prop.LB_prizes)
                  setSelectedKey(prop.date)
                }}
              >
                {d.value}
              </p>
            )
          })}
        </div>
        <table className={styles.table}>
          <thead>
            <tr>
              <th>Contest Entry Fee</th>
              <th className={styles.center}>Winners</th>
              <th className={styles.right}>Prize</th>
            </tr>
          </thead>
          <tbody>
            {dateValues?.map((d, i) => {
              return (
                <tr key={i}>
                  <td>Free</td>
                  <td className={styles.center}>{d.winners}</td>
                  <td className={styles.right}>
                    {`₹${d.prize.toLocaleString('en-IN')}`}
                  </td>
                </tr>
              )
            })}
          </tbody>
        </table>
      </CollapsebaleSection>
    </div>
  )
}

export default DatewisePrizeDistribution
