import React from 'react'
import styles from './GoalStepper.module.scss'
import { GoalBased, PrizeDistribution } from '../../types/leaderboard'
import LockedIcon from '../../assets/images/locked.png'
import { LeaderboardEntry } from '../../types/leaderboard-entry'
import { getPlayerId } from '../../auth-token'
import { calculateCurrentGoal } from '../../helpers/constants'
import CurrentIcon from '../../assets/images/current.png'
import CompletedIcon from '../../assets/images/completed.png'
import MissedIcon from '../../assets/images/missed.png'

interface Props {
  prize_distribution: PrizeDistribution
  selectedGoal: GoalBased
  setSelectedGoal: Function
  entries: LeaderboardEntry[]
  state: string
}

const GoalStepper: React.FC<Props> = ({
  prize_distribution,
  selectedGoal,
  setSelectedGoal,
  entries,
  state,
}) => {
  const goals = prize_distribution.goal_based || []
  const myPlayerId = getPlayerId()
  const myEntry = entries.find(e => e.player.player_id === myPlayerId)
  const currentGoal = calculateCurrentGoal(
    prize_distribution,
    myEntry?.score || 0
  )
  const getIconForSelectedGoal = (g: GoalBased, currentGoal: number) => {
    if (currentGoal == 0 && state !== 'ResultDeclared') {
      return LockedIcon
    }

    const stepperGoal = Math.ceil(g.goal)

    if (currentGoal > stepperGoal) {
      return CompletedIcon
    }

    if (state === 'Live') {
      if (currentGoal == stepperGoal) {
        return CurrentIcon
      }

      if (currentGoal < stepperGoal) {
        return LockedIcon
      }
    }

    if (state === 'ResultDeclared') {
      if (currentGoal <= stepperGoal) {
        return MissedIcon
      }
    }

    return LockedIcon
  }

  return (
    <div className={styles.container}>
      {goals.map(g => {
        const goalNum = Math.ceil(g.goal)
        return (
          <div
            className={styles.imageContainer}
            style={{ minWidth: `${100 / goals.length}%` }}
            onClick={() => {
              setSelectedGoal(g)
            }}
          >
            {goalNum != 1 && goalNum != goals.length && (
              <div
                className={styles.hr}
                style={{ minWidth: `${(100 * 1.8) / goals.length}%` }}
              />
            )}
            {g.goal === selectedGoal.goal && (
              <div className={styles.selectedGoal} />
            )}
            <img
              src={getIconForSelectedGoal(g, currentGoal)}
              className={styles.icon}
              //@ts-ignore
              style={g.goal === selectedGoal ? { marginTop: 15 } : {}}
              alt="Goal Icon"
            />
            {g.goal === selectedGoal.goal && (
              <span className={styles.prize}>Prize</span>
            )}
            <span
              className={styles.prizeAmount}
              //@ts-ignore
              style={
                g.goal === selectedGoal.goal
                  ? { color: 'black', fontWeight: '500', fontSize: 14 }
                  : {}
              }
            >
              ₹{g.prize.winning_amount}
            </span>
          </div>
        )
      })}
    </div>
  )
}

export default GoalStepper
