import React, { createContext, ReactNode, useContext, useState } from 'react'

type Props = {
  children: ReactNode
}

export enum SortOrder {
  ASC,
  DESC,
}

export interface Option {
  value: string
  label: string
}

export enum SortField {
  Recommended = 'Recommended',
  PrizeMoney = 'Prize Money',
  BigBlind = 'Big Blind',
  Time = 'Time',
  PointRate = 'Point Rate',
}

export interface Sort {
  field: SortField
  order: SortOrder
}

interface SortContextType {
  sort: Sort
  setSort(sort: Sort): void
}

const SortContext = createContext<SortContextType | undefined>(undefined)

const SortProvider = ({ children }: Props): JSX.Element => {
  const [sort, setSort] = useState({
    field: SortField.Recommended,
    order: SortOrder.ASC,
  })

  return (
    <SortContext.Provider value={{ sort, setSort }}>
      {children}
    </SortContext.Provider>
  )
}

export interface ViewProp {
  id: string
  header: string
  pills: Pill[]
}

export interface Pill {
  id: string
  value: string
  type?: string
  showIcon?: boolean
}

export const SortViewMap: ViewProp[] = [
  {
    id: 'pre-applied',
    header: 'Pre Applied',
    pills: [
      {
        id: 'recommended',
        value: 'Recommended',
        type: 'ASC',
      },
    ],
  },
  {
    id: 'prize-money',
    header: 'Prize Money',
    pills: [
      {
        id: 'pm-up',
        value: 'Prize Money',
        type: 'ASC',
        showIcon: true,
      },
      {
        id: 'pm-down',
        value: 'Prize Money',
        type: 'DESC',
        showIcon: true,
      },
    ],
  },
  {
    id: 'big-blinds',
    header: 'Big Blinds',
    pills: [
      {
        id: 'bb-up',
        value: 'Big Blind',
        type: 'ASC',
        showIcon: true,
      },
      {
        id: 'bb-down',
        value: 'Big Blind',
        type: 'DESC',
        showIcon: true,
      },
    ],
  },
  {
    id: 'point-rates',
    header: 'Point Rate',
    pills: [
      {
        id: 'pr-up',
        value: 'Point Rate',
        type: 'ASC',
        showIcon: true,
      },
      {
        id: 'pr-down',
        value: 'Point Rate',
        type: 'DESC',
        showIcon: true,
      },
    ],
  },
  {
    id: 'duration',
    header: 'Duration',
    pills: [
      {
        id: 'd-up',
        value: 'Longest First',
        type: 'ASC',
      },
      {
        id: 'd-down',
        value: 'Shortest First',
        type: 'DESC',
      },
    ],
  },
]

export const SortIdToSort: Record<string, Sort> = {
  recommended: {
    field: SortField.Recommended,
    order: SortOrder.ASC,
  },
  'pm-up': {
    field: SortField.PrizeMoney,
    order: SortOrder.ASC,
  },
  'pm-down': {
    field: SortField.PrizeMoney,
    order: SortOrder.DESC,
  },
  'd-up': {
    field: SortField.Time,
    order: SortOrder.ASC,
  },
  'd-down': {
    field: SortField.Time,
    order: SortOrder.DESC,
  },
  'bb-up': {
    field: SortField.BigBlind,
    order: SortOrder.ASC,
  },
  'bb-down': {
    field: SortField.BigBlind,
    order: SortOrder.DESC,
  },
  'pr-up': {
    field: SortField.PointRate,
    order: SortOrder.ASC,
  },
  'pr-down': {
    field: SortField.PointRate,
    order: SortOrder.DESC,
  },
}

export default SortProvider
export const useSort = (): SortContextType | undefined =>
  useContext(SortContext)
