import React, { useState, useEffect } from 'react'
import dayjs, { Dayjs } from 'dayjs'
import isBetween from 'dayjs/plugin/isBetween'
import styles from './WeekCalendar.module.scss'
import { Swipeable } from 'react-swipeable'

interface Props {
  date: Dayjs
  onDayClick(date: Dayjs): void
}

const WeekCalendar: React.FC<Props> = ({ date, onDayClick }) => {
  dayjs.extend(isBetween)

  const today = dayjs().startOf('day')
  const [days, setDays] = useState<Dayjs[]>([])
  const [weekStart, setWeekStart] = useState(today)

  const getWeekDays = (ws: Dayjs): Dayjs[] => {
    const d = []
    for (let i = 0; i < 7; i += 1) {
      d.push(dayjs(ws).add(i, 'day'))
    }

    return d
  }

  useEffect(() => {
    const firstWeekStart = dayjs().startOf('day').subtract(1, 'day')
    const secondWeekStart = firstWeekStart.add(1, 'week')

    const ws = date.isBetween(firstWeekStart, secondWeekStart, null, '[)')
      ? firstWeekStart
      : secondWeekStart
    setWeekStart(ws)
    const d = getWeekDays(ws)
    setDays(d)
  }, [date])

  return (
    <div className={styles.WeekCalendar}>
      <Swipeable
        onSwipedLeft={(): void => {
          let thisWeek = dayjs().startOf('day').subtract(1, 'day')
          if (weekStart.toISOString() === thisWeek.toISOString()) {
            const ws = weekStart.clone().add(7, 'day')
            setWeekStart(ws)
            const d = getWeekDays(ws)
            setDays(d)
          }
        }}
        onSwipedRight={(): void => {
          let nextWeek = dayjs().startOf('day').add(6, 'day')
          if (weekStart.toISOString() === nextWeek.toISOString()) {
            const ws = weekStart.clone().add(-7, 'day')
            setWeekStart(ws)
            const d = getWeekDays(ws)
            setDays(d)
          }
        }}
        preventDefaultTouchmoveEvent
      >
        <div className={styles.days}>
          {days.map(d => {
            return (
              <div
                key={d.format()}
                role="button"
                tabIndex={0}
                className={`${styles.day} ${
                  d.isSame(date, 'day') ? styles.selected : ''
                }`}
                onClick={(): void => {
                  onDayClick(d)
                }}
                onKeyDown={(e): void => {
                  if (e.key === 'Enter') {
                    onDayClick(d)
                  }
                }}
              >
                <div className={styles.weekDay}>{d.format('dd')[0]}</div>
                <div className={styles.date}>{d.format('D')}</div>

                {d.isSame(today, 'day') ? (
                  <div className={styles.today}>
                    <div className={styles.dot} />
                  </div>
                ) : null}
              </div>
            )
          })}
        </div>
      </Swipeable>

      <div className={styles.todayText}>
        {`${
          today.isSame(date, 'day') ? 'Today' : date.format('dddd')
        }, ${date.format('Do MMMM')}`}
      </div>
    </div>
  )
}

export default WeekCalendar
