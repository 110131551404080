import React from 'react'
import useCountdown from '../../hooks/useCountdown'

interface Props {
  eventTime: Date
}

const Countdown: React.FC<Props> = ({ eventTime }) => {
  const cd = useCountdown(eventTime)

  return (
    <>
      <span>{cd.days || '0'}</span>
      D&nbsp;
      <span>{cd.hours || '0'}</span>
      H&nbsp;
      <span>{cd.minutes || '0'}</span>
      M&nbsp;
      <span>{cd.seconds || '0'}</span>
      S&nbsp;
    </>
  )
}

export default Countdown
