import React, { useState } from 'react'
import './CollapseableSection.scss'
import LinesIcon from '../../assets/images/lines.png'
import Chevron from '../../assets/images/cheveron-up.png'

interface Props {
  title: string
  contentClassName?: any
  onHeaderClick?(expanded: boolean): void
}

const CollapsebaleSection: React.FC<Props> = ({
  title,
  contentClassName,
  onHeaderClick,
  children,
}) => {
  const [expanded, setExpanded] = useState<boolean>(true)

  return (
    <div id="CollapsebaleSection">
      <div
        className="cs-pageTitle"
        role="button"
        tabIndex={0}
        onClick={(): void => {
          setExpanded(!expanded)
          if (onHeaderClick) onHeaderClick(!expanded)
        }}
        onKeyDown={(e): void => {
          if (e.key === 'Enter') {
            setExpanded(!expanded)

            if (onHeaderClick) onHeaderClick(!expanded)
          }
        }}
      >
        <div className="cs-headerDiv">
          <img src={LinesIcon} className="cs-lines" alt="" />
          <div className="cs-headerText">{title}</div>
        </div>
        <img
          src={Chevron}
          className={expanded ? 'cs-chevron-expanded' : 'cs-chevron'}
          alt=""
        />
      </div>
      <div
        className={`cs-content ${contentClassName}`}
        style={{ display: expanded ? 'block' : 'none' }}
      >
        {children}
      </div>
    </div>
  )
}

export default CollapsebaleSection
