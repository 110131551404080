import React from 'react'
import styles from './CurrentRank.module.scss'
import { LeaderboardDetails } from '../../types/leaderboard-details'
import { State, GoalBased } from '../../types/leaderboard'
import { LeaderboardEntry } from '../../types/leaderboard-entry'
import LockedIcon from '../../assets/images/locked.png'
import CurrentIcon from '../../assets/images/current.png'
import CompletedIcon from '../../assets/images/completed.png'
import MissedIcon from '../../assets/images/missed.png'
import { getPlayerId } from '../../auth-token'
import {
  calculateCurrentGoal,
  getLastGoal,
  MetricFieldTournamentConfig,
} from '../../helpers/constants'

interface Props {
  lbDetails: LeaderboardDetails
  entries: LeaderboardEntry[]
  selectedGoal: GoalBased
}

const CurrentGoal: React.FC<Props> = ({ lbDetails, entries, selectedGoal }) => {
  if (lbDetails.state === State.ResultDeclared && !lbDetails.player_rank) {
    return null
  }

  const getCardIcon = (
    lbState: string,
    selectedGoal: GoalBased,
    currentGoal: number
  ): string => {
    if (lbState === State.Open) {
      return LockedIcon
    }

    const goal = Math.ceil(selectedGoal.goal)

    if (lbState === State.Live) {
      if (goal > currentGoal) {
        return LockedIcon
      } else if (goal === currentGoal) {
        return CurrentIcon
      } else if (goal <= currentGoal) {
        return CompletedIcon
      }
    }

    if (lbState === State.ResultDeclared) {
      if (goal >= currentGoal) {
        return MissedIcon
      } else if (goal <= currentGoal) {
        return CompletedIcon
      }
    }

    return LockedIcon
  }

  const getBgForLb = (
    lbDetails: LeaderboardDetails,
    currentGoal: number,
    myEntry?: LeaderboardEntry
  ) => {
    if (lbDetails.state === State.ResultDeclared) {
      if (currentGoal === 0 || (myEntry && myEntry.prize === 0)) {
        return '#FFEFEE'
      }
    }

    return '#f4f4ff'
  }

  // find logged in player's entry
  const myPlayerId = getPlayerId()
  const myEntry = entries.find(e => e.player.player_id === myPlayerId)
  const currentGoal = calculateCurrentGoal(
    lbDetails.prize_distribution,
    myEntry?.score || 0
  )
  const lastGoal = getLastGoal(lbDetails.prize_distribution)
  const totalGoals = lbDetails.prize_distribution.goal_based.length

  const progress = Math.min(
    ((myEntry?.score || 0) / lastGoal.winning_score) * 100,
    100
  )

  return (
    <div
      id={styles.CurrentRank}
      style={{
        background: getBgForLb(lbDetails, currentGoal, myEntry),
      }}
    >
      <div className={styles.titleDiv}>
        <div className={styles.pageTitle}>
          Goal {selectedGoal.goal} / {totalGoals}
        </div>
        <img
          src={getCardIcon(lbDetails.state, selectedGoal, currentGoal)}
          className={styles.cardIcon}
          alt="state icon"
        />
      </div>

      {lbDetails.state === State.Live ? (
        <div className={styles.currentRank}>
          {selectedGoal.goal >= currentGoal
            ? `Win ₹${selectedGoal.prize.winning_amount} on completion of this goal`
            : `You won ₹${selectedGoal.prize.winning_amount} on completion of this goal`}
        </div>
      ) : null}

      {lbDetails.state === State.ComputingResult ? (
        <div className={styles.currentRank}>Final Goal: -</div>
      ) : null}

      {lbDetails.state === State.ResultDeclared && (
        <div className={styles.currentRank}>
          {selectedGoal.goal >= currentGoal
            ? `You could have won ₹${selectedGoal.prize.winning_amount} on completion of this goal`
            : `You won ₹${selectedGoal.prize.winning_amount} on completion of this goal`}
        </div>
      )}

      {lbDetails.state === State.Live ? (
        <div className={styles.currentScore}>
          Your Current Score is{' '}
          <span>
            {lbDetails.player_score?.toFixed(2).replace('.00', '') || '0'} /{' '}
            {selectedGoal.winning_score}
          </span>
        </div>
      ) : null}

      {lbDetails.state === State.ResultDeclared ||
      lbDetails.state === State.ComputingResult ? (
        <div className={styles.currentScore}>
          Your Final Score is{' '}
          <span>
            {!!lbDetails.player_score
              ? lbDetails.player_score?.toFixed(2).replace('.00', '')
              : '0'}
          </span>
        </div>
      ) : null}

      <div
        className={styles.progress}
        style={{
          background: `linear-gradient(90deg, #276ef1 ${progress}%, #817272 ${progress}%)`,
        }}
      />
      {lbDetails.state === State.Live ? (
        <>
          {currentGoal === 0 ? (
            <div>
              Score minimum of 1 in tournaments
              {lbDetails.filters.map(f => {
                if (
                  f.metric_field.toString() === 'Bigblind' ||
                  f.metric_field.toString() === 'PointRate'
                ) {
                  return f.value_range.number_min === f.value_range.number_max
                    ? ` with ${
                        MetricFieldTournamentConfig[f.metric_field.toString()]
                      } more than ₹${f.value_range.number_min}`
                    : ` between ${
                        MetricFieldTournamentConfig[f.metric_field.toString()]
                      }s ₹${f.value_range.number_min} - ₹${
                        f.value_range.number_max
                      }`
                }

                return ''
              })}
              <span> to qualify for any cash prize</span>
            </div>
          ) : (
            <>
              {selectedGoal.goal > currentGoal && (
                <div>Complete your previous goals to unlock this Goal</div>
              )}
              {selectedGoal.goal === currentGoal && (
                <div>
                  You need to score{' '}
                  {selectedGoal.winning_score - (myEntry?.score || 0)} to
                  complete this goal
                </div>
              )}
              {selectedGoal.goal < currentGoal && (
                <div>Yay! you completed this goal</div>
              )}
            </>
          )}
        </>
      ) : null}

      {lbDetails.state === State.ResultDeclared ? (
        <>
          {selectedGoal.goal >= currentGoal && (
            <div>
              Oops! You missed this goal! Try another leaderboard to win cash
              prizes
            </div>
          )}
          {selectedGoal.goal < currentGoal && (
            <div>Yay! you completed this goal</div>
          )}
        </>
      ) : null}
    </div>
  )
}

export default CurrentGoal
