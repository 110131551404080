import { useState, useEffect } from 'react'
import countdown from 'countdown'

const useCountdown = (eventTime) => {
  const [days, setDays] = useState('')
  const [hours, setHours] = useState('')
  const [minutes, setMinutes] = useState('')
  const [seconds, setSeconds] = useState('')

  useEffect(() => {
    const interval = setInterval(() => {
      const now = new Date()

      if (now < eventTime) {
        const rt = countdown(eventTime)

        if (rt.days !== days) setDays(rt.days)
        if (rt.hours !== hours) setHours(rt.hours)
        if (rt.minutes !== minutes) setMinutes(rt.minutes)
        if (rt.seconds !== seconds) setSeconds(rt.seconds)
      } else {
        clearInterval(interval)
      }
    }, 1000)

    return () => clearInterval(interval)
  }, [eventTime, days, hours, minutes, seconds])

  return {
    days,
    hours,
    minutes,
    seconds,
  }
}

export default useCountdown
