import React from 'react'
import styles from './RewardDetails.module.scss'

import { TaskUiInfo } from '../../types/leaderboard'

interface Props {
  rewardDetails: TaskUiInfo
  setShowPrizeDistribution(arg: boolean): void
}

const RewardDetails: React.FC<Props> = ({
  rewardDetails,
  setShowPrizeDistribution,
}) => {
  return (
    <div id={styles.rewardDetails}>
      <div className={styles.title}>
        <div
          role="button"
          className={styles.arrow}
          onClick={(): void => {
            setShowPrizeDistribution(true)
          }}
          onKeyDown={(e): void => {
            if (e.key === 'Enter') {
              setShowPrizeDistribution(true)
            }
          }}
          tabIndex={0}
        >
          ←
        </div>
        <div className={styles.header}>{rewardDetails.title}</div>
      </div>

      <div className={styles.subTitle}>{rewardDetails.sub_title}</div>
      <div className={styles.separator} />
      <div className={styles.rewardInfoTitle}>
        {rewardDetails.reward_info_title}
      </div>
      {rewardDetails.subtasks.map((t, i) => {
        return (
          <div key={t.description.title} className={styles.rewards}>
            <div className={styles.subTask}>
              <div className={styles.progress}>
                <div className={styles.circle} />
                {i < rewardDetails.subtasks.length - 1 && (
                  <div className={styles.vseparator} />
                )}
              </div>
              <div className={styles.description}>
                <div className={styles.title}>{t.description.title}</div>
                <div className={styles.subTitle}>{t.description.sub_title}</div>
              </div>
              <div className={styles.iconInfo}>
                <img src={t.icon_info.icon_url} alt="icon" />
                <div className={styles.iconText}>
                  ₹{t.icon_info.below_icon_text}
                </div>
              </div>
            </div>
            {i < rewardDetails.subtasks.length - 1 && (
              <div className={styles.separator} />
            )}
          </div>
        )
      })}
    </div>
  )
}
export default RewardDetails
