import axios from 'axios'
import { LeaderboardEntry } from '../../types/leaderboard-entry'
import getAuthToken from '../../auth-token'

const getLeaderboardEntries = async (
  leaderboardId: string
): Promise<{
  entries: LeaderboardEntry[]
  error: string
}> => {
  const url = `${process.env.REACT_APP_MAPI_HOST_URI}/twirp/mapi.pb.ms.v1.LeaderboardsService/Entries`
  const data = {
    leaderboard_id: leaderboardId,
    count: 30,
    offset: 0,
  }
  const token = getAuthToken()

  return axios({
    method: 'POST',
    url,
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`,
    },
    data,
  })
    .then(response => {
      const { entries } = response.data
      return {
        entries: entries || [],
        error: '',
      }
    })
    .catch(error => {
      console.error(error)
      return {
        entries: [],
        error: 'Error fetching leaderboard entries',
      }
    })
}

export default getLeaderboardEntries
