// Initialize Firebase

import * as firebase from 'firebase/app'
import '@firebase/firestore' // 👈 If you're using firestore
// import ReduxSagaFirebase from 'redux-saga-firebase'
import 'firebase/performance'

const myFirebaseApp = firebase
  .initializeApp({
    apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
    authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
    databaseURL: process.env.REACT_APP_FIREBASE_DATABASE_URL,
    projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
    appId: process.env.REACT_APP_FIREBASE_APP_ID,
    storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
    messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_ID,
  })
  .performance()

// export const rsf = new ReduxSagaFirebase(myFirebaseApp)
export default myFirebaseApp
