import React, { useCallback, useEffect } from 'react'
import styles from './Filters.module.scss'
import Slider, { createSliderWithTooltip } from 'rc-slider'
import 'rc-slider/assets/index.css'
import filterConfig from './filterConfig'
import {
  Filter,
  FilterOptionsType,
  FilterPillsType,
  FilterSliderType,
  FilterType,
  FilterValues,
} from '../../types/filters'
import CheckedIcon from '../../assets/images/checked.png'
import CloseIcon from '../../assets/images/close.png'

interface Props {
  selectedFilters: Record<string, any>
  setFilters: Function
  applyFilters: Function
  resetFilters: Function
  setShowFilters: Function
}

const SilderWithToolTip = createSliderWithTooltip(Slider.Range)

const Filters: React.FC<Props> = ({
  selectedFilters,
  setFilters,
  applyFilters,
  resetFilters,
  setShowFilters,
}) => {
  useEffect(() => {
    // disabling background scroll
    document.body.style.overflow = 'hidden'

    // enabling background scroll on closing filters
    return (): void => {
      document.body.style.overflow = 'unset'
    }
  }, [])

  const apply = useCallback(() => {
    applyFilters()
  }, [applyFilters])

  const reset = useCallback(() => {
    resetFilters()
    setFilters({})
  }, [resetFilters, setFilters])

  const toggleFilterSelect = (f: Filter, option: FilterValues) => () => {
    let tempFilters = { ...selectedFilters }
    if (!!tempFilters[f.key]) {
      const values = tempFilters[f.key] as FilterValues[]
      if (values.includes(option)) {
        tempFilters[f.key] = values.filter(v => v !== option)
        if (tempFilters[f.key].length === 0) {
          delete tempFilters[f.key]
        }
      } else {
        tempFilters[f.key] = [...values, option]
      }
    } else {
      tempFilters[f.key] = [option]
    }
    setFilters(tempFilters)
  }

  const toggleSliderSelect = (f: Filter) => (values: number[]) => {
    let tempFilters = { ...selectedFilters }
    tempFilters[f.key] = { sliderMin: values[0], sliderMax: values[1] }
    setFilters(tempFilters)
  }

  const showConditionsFulfilled = (
    f: Filter,
    selectedFilters: Record<string, any>
  ) => {
    let showFulfilled = true
    let showConditions = f.showConditions || {}
    Object.keys(showConditions).forEach(k => {
      let sf = selectedFilters[k]
      if (!!sf) {
        if (showConditions[k] === 'Not Poker') {
          showFulfilled = !sf.includes('Poker')
        } else if (!sf.includes(showConditions[k])) showFulfilled = false
      } else {
        showFulfilled = false
      }
    })

    return showFulfilled
  }

  return (
    <div className={styles.container}>
      <div className={styles.iconDiv}>
        <img
          src={CloseIcon}
          className={styles.close}
          onClick={() => {
            setShowFilters(false)
          }}
          alt="Close Icon"
        />
      </div>

      <div className={styles.headerContainer}>
        <span className={styles.reset} onClick={reset}>
          Reset
        </span>
        <span className={styles.reset}>Filters</span>
        <span className={styles.apply} onClick={apply}>
          Apply
        </span>
      </div>
      <div className={styles.filterContainer}>
        {filterConfig.map(f => {
          if (
            f.forceHide ||
            (f.hidden && !showConditionsFulfilled(f, selectedFilters))
          ) {
            return null
          }
          switch (f.type) {
            case FilterType.options:
              const selectedOptions =
                !!selectedFilters[f.key] &&
                selectedFilters[f.key] instanceof Array
                  ? selectedFilters[f.key]
                  : []
              return (
                <div className={styles.optionsContainer}>
                  <div className={styles.header}>{f.header}</div>
                  <div className={styles.options}>
                    {(f.typeData as FilterOptionsType).options.map((o, i) => (
                      <div
                        className={styles.optionContainer}
                        onClick={toggleFilterSelect(f, o)}
                        key={i}
                      >
                        <span className={styles.option}>{o.valueOf()}</span>
                        {selectedOptions.includes(o) && (
                          <img
                            src={CheckedIcon}
                            className={styles.icon}
                            alt="Checked Icon"
                          />
                        )}
                      </div>
                    ))}
                  </div>
                </div>
              )
            case FilterType.pills:
              const selectedPills = !!selectedFilters[f.key]
                ? selectedFilters[f.key]
                : []
              return (
                <div className={styles.pillsContainer}>
                  <div className={styles.header}>{f.header}</div>
                  <div className={styles.pills}>
                    {(f.typeData as FilterPillsType).pills.map(p => (
                      <span
                        className={`${styles.pill} ${
                          selectedPills.includes(p) ? styles.filterSelected : ''
                        }`}
                        onClick={toggleFilterSelect(f, p)}
                      >
                        {p.valueOf()}
                      </span>
                    ))}
                  </div>
                </div>
              )

            case FilterType.slider:
              const typeData = f.typeData as FilterSliderType
              const values = !!selectedFilters[f.key]
                ? [
                    selectedFilters[f.key].sliderMin,
                    selectedFilters[f.key].sliderMax,
                  ]
                : [0, 0]
              return (
                <div className={styles.sliderContainer}>
                  <div className={styles.headerAndValue}>
                    <div className={styles.header}>{f.header}</div>
                    {(values[0] > 0 || values[1] > 0) && (
                      <span className={styles.header}>
                        {values[0]} - {values[1]}
                      </span>
                    )}
                  </div>

                  <div className={styles.slider}>
                    <SilderWithToolTip
                      min={typeData.sliderMin}
                      max={typeData.sliderMax}
                      onChange={toggleSliderSelect(f)}
                      value={values}
                    />
                  </div>
                </div>
              )
            default:
              return null
          }
        })}
      </div>
    </div>
  )
}

export default Filters
