import React, { useCallback, useEffect, useState } from 'react'
import { RouteComponentProps } from '@reach/router'
import dayjs from 'dayjs'

import styles from './LeaderboardDetails.module.scss'
import GeneralRules from '../../components/GeneralRules/GeneralRules'
import CurrentRank from './CurrentRank'
import HowToWin from '../../components/HowToWin/HowToWin'
import LeaderboardRules from '../../components/LeaderboardRules/LeaderboardRules'
import LeaderboardAndSummary from './LeaderboardAndSummary'
import { LeaderboardDetails as LeaderboardDetailsType } from '../../types/leaderboard-details'
import { GoalBased, LbType, RankBased, State } from '../../types/leaderboard'
import Countdown from './Countdown'
import PrizeInfo from './PrizeInfo'
import { getEntrySummary, getLeaderboardDetails } from './api'
import { EntrySummary, LeaderboardEntry } from '../../types/leaderboard-entry'
import getLeaderboardEntries from '../../components/Leaderboard/api'
import { getPlayerId } from '../../auth-token'
import StickyFooter from '../../components/StickyFooter/StickyFooter'
import PreviousWinners from '../../components/PreviousWinners/PreviousWinners'
import config from './config'
import Testimonials from '../../components/Testimonials/Testimonials'
import Banners from '../../components/Banners/Banners'
import { generateCTA } from '../../helpers/url-generator'
import PurchaseCard from '../../components/PurchaseCard/PurchaseCard'
import GoalStepper from '../../components/GoalStepper/GoalStepper'
import CurrentGoal from './CurrentGoal'
import {
  calculateCurrentGoal,
  getBBOrPr,
  getGameName,
} from '../../helpers/constants'
import OptInCard from '../../components/OptInCard/OptIn'
import TopBanners from '../../components/TopBanners/TopBanners'

type Props = RouteComponentProps<{
  leaderboardId: string
}>

const LeaderboardDetails: React.FC<Props> = props => {
  const { leaderboardId } = props
  const [loading, setLoading] = useState(true)
  const [lbDetails, setLbDetails] = useState<
    LeaderboardDetailsType | undefined
  >()
  const [, setError] = useState('')
  const [firstRankerScore, setFirstRankerScore] = useState(0)
  const [showPurchaseCard, setShowPurchaseCard] = useState(false)
  const [showOptInCard, setShowOptInCard] = useState(false)
  const [selectedGoal, setSelectedGoal] = useState<GoalBased>({
    goal: 0,
    winning_score: 0,
    prize: {
      winning_amount: 0,
      display_value: '',
      wallet_type: '',
    },
    goal_name: '',
  })

  const [entries, setEntries] = useState<LeaderboardEntry[]>([])
  const fetchLeaderboardEntries = async (
    id: string,
    details: LeaderboardDetailsType
  ): Promise<LeaderboardEntry[]> => {
    setLoading(true)
    const { entries: e, error: err } = await getLeaderboardEntries(id)

    if (e.length > 0) {
      setFirstRankerScore(e[0].score)
    }

    if (
      !!details?.prize_distribution.goal_based &&
      details?.prize_distribution.goal_based.length > 0
    ) {
      const p = details.prize_distribution
      const myPlayerId = getPlayerId()
      const myEntry = e.find(ent => ent.player.player_id === myPlayerId)
      const currentGoal = calculateCurrentGoal(p, myEntry?.score || 0)
      let userCurrentGoal = p.goal_based.find(
        g => Math.ceil(g.goal) === currentGoal
      )

      if (currentGoal > p.goal_based.length) {
        userCurrentGoal = p.goal_based[p.goal_based.length - 1]
      }

      if (userCurrentGoal) setSelectedGoal(userCurrentGoal)
    }

    setEntries(e)
    setError(err)
    setLoading(false)
    return e
  }

  useEffect(() => {
    if (window.location.href.includes('purchaseCard')) {
      setShowPurchaseCard(true)
    }
  }, [])

  const [entrySummary, setEntrySummary] = useState<EntrySummary[]>([])
  const fetchEntrySummary = async (id: string): Promise<EntrySummary[]> => {
    setLoading(true)
    const { summary: s, error: err } = await getEntrySummary(id)

    setEntrySummary(s)
    setError(err)
    setLoading(false)
    return s
  }

  const fetchLeaderboardDetails = useCallback(
    async (id: string): Promise<void> => {
      setLoading(true)
      const { details, error: err } = await getLeaderboardDetails(id)
      setLbDetails(details)
      if (
        !!details?.prize_distribution.goal_based &&
        details?.prize_distribution.goal_based.length > 0
      ) {
        setSelectedGoal(details?.prize_distribution.goal_based[0])
      }
      setError(err)
      setLoading(false)

      const entrs = await fetchLeaderboardEntries(id, details)
      fetchEntrySummary(id)

      try {
        const playerId = getPlayerId()
        const rank =
          (entrs as LeaderboardEntry[]).find(
            en => en.player.player_id === playerId
          )?.rank || Infinity

        let numberOfWinners = 0
        if (
          details.prize_distribution &&
          details.prize_distribution.rank_based
        ) {
          details.prize_distribution.rank_based.forEach((d: any) => {
            numberOfWinners +=
              Math.ceil(d.end_rank) - Math.ceil(d.start_rank) + 1
          })
        } else if (
          details.prize_distribution &&
          details.prize_distribution.goal_based
        ) {
          numberOfWinners = details.prize_distribution.goal_based.length
        }
        const payload = {
          leaderboard_name: details?.external_name,
          leaderboard_id: leaderboardId,
          current_rank: details?.player_rank,
          current_score: details?.player_score,
          total_participants: details?.total_participants,
          time_remaining:
            details?.state === State.Live
              ? `${dayjs(details.close_time)
                  .diff(dayjs(), 'hour', true)
                  .toFixed(2)} hours`
              : '',
          status: details?.state,
          prize_won:
            details?.state === State.ResultDeclared &&
            rank <=
              details.prize_distribution.rank_based[
                details.prize_distribution.rank_based.length - 1
              ].end_rank
              ? details.prize_distribution.rank_based.find(
                  (r: RankBased) => r.start_rank <= rank && r.end_rank >= rank
                )?.prize.winning_amount
              : 0,
          number_of_active_participants: details?.total_participants,
          starts_in:
            details?.state === State.Open || details?.state === State.Draft
              ? `${dayjs(details.live_time)
                  .diff(dayjs(), 'hour', true)
                  .toFixed(2)} hours`
              : '',
          leaderboard_prize_type: details?.type,
          goal_number_achieved: '',
          current_goal: '',
          goal_value: '',
          attribute_for_the_goal: '',
          leaderboard_entry_type: '',
          original_leaderboard_entry_fee: '',
          changed_leaderboard_entry_fee: '',
          source_screen: 'My leaderboard detail',
          game_name: getGameName(details?.filters),
          deeplink: window.location.href,
          totalPrize: details?.total_prize_amount,
          is_opt_in: details?.is_opt_in,
          numberOfWinners,
          bb_or_pr: getBBOrPr(details?.filters),
          internal_name: details?.internal_name,
        }
        window.analytics.track('LeaderboardDetailsScreenViewed', payload)
        window.rudderanalytics.track('LeaderboardDetailsScreenViewed', payload)
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        clevertap.event.push('LeaderboardDetailsScreenViewed', payload)
      } catch (er) {
        console.error(er)
      }
    },
    [leaderboardId]
  )

  useEffect(() => {
    if (leaderboardId) {
      fetchLeaderboardDetails(leaderboardId)
    }
  }, [leaderboardId, fetchLeaderboardDetails])

  const sendPlayNowClickEvent = (btnLocation: string): void => {
    try {
      window.analytics.track('PlayNowButtonClicked', {
        time_remaining: `${dayjs(lbDetails?.close_time)
          .diff(dayjs(), 'hour', true)
          .toFixed(2)} hours`,
        warning_shown: '',
        entry_point: btnLocation,
        source_screen: 'My leaderboard detail',
      })
    } catch (er) {
      console.error(er)
    }
    try {
      window.rudderanalytics.track('PlayNowButtonClicked', {
        time_remaining: `${dayjs(lbDetails?.close_time)
          .diff(dayjs(), 'hour', true)
          .toFixed(2)} hours`,
        warning_shown: '',
        entry_point: btnLocation,
        source_screen: 'My leaderboard detail',
      })
    } catch (er) {
      console.error(er)
    }
  }

  const sendJoinNowClickEvent = (btnLocation: string): void => {
    try {
      window.analytics.track('JoinNowClicked', {
        leaderboard_id: leaderboardId,
        entry_point: btnLocation,
        source_screen: 'My leaderboard detail',
      })
    } catch (error) {
      console.error(error)
    }
    try {
      window.rudderanalytics.track('JoinNowClicked', {
        leaderboard_id: leaderboardId,
        entry_point: btnLocation,
        source_screen: 'My leaderboard detail',
      })
    } catch (error) {
      console.error(error)
    }
  }

  const getPaidLbEntryFeeHtml = () => {
    const entryFee = lbDetails.entry_fee?.amount || 0
    if (
      !!lbDetails.offers &&
      lbDetails.offers?.length > 0 &&
      lbDetails.state !== State.ResultDeclared
    ) {
      const offer = lbDetails.offers[0]
      const feeAfterDisc = entryFee - offer.reward.Amount
      return (
        <div>
          <span style={{ textDecoration: 'line-through', color: '#ED4F46' }}>
            <span style={{ color: 'white' }}>₹{entryFee}</span>
          </span>{' '}
          <span>₹{feeAfterDisc}</span>
        </div>
      )
    }
    return <span>₹{entryFee}</span>
  }

  const showPayNowButton = () => {
    return (
      lbDetails?.category === 'PAID' &&
      !lbDetails?.has_joined &&
      (lbDetails?.state === State.Open ||
        (lbDetails?.state === State.Live &&
          lbDetails?.late_registration_allowed))
    )
  }

  const showOptInButton = () => {
    return (
      lbDetails?.category !== 'PAID' &&
      !lbDetails?.has_joined &&
      lbDetails?.is_opt_in &&
      (lbDetails?.state === State.Open ||
        (lbDetails?.state === State.Live &&
          lbDetails?.late_registration_allowed))
    )
  }

  return (
    <>
      {loading ? (
        <div className={styles.loading}>Loading leaderboard details ...</div>
      ) : (
        <div id={styles.LeaderboardDetails}>
          <div className={styles.pageTitle}>{lbDetails?.external_name}</div>

          <div
            className={styles.header}
            style={
              lbDetails?.type === LbType.GoalBased.toString()
                ? { paddingBottom: 0 }
                : {}
            }
          >
            {lbDetails?.has_non_cash_prizes ? (
              <TopBanners banners={lbDetails.ui_data.banners} />
            ) : null}

            <div className={styles.contestDescription}>
              <span>{lbDetails?.description}</span>
            </div>
            <div className={styles.timeRemaining}>
              {lbDetails?.category !== 'PAID' &&
              (lbDetails?.state === State.Draft ||
                lbDetails?.state === State.Open) ? (
                <span>Starts in</span>
              ) : null}

              {lbDetails?.state === State.Live ? (
                <span>Time Remaining</span>
              ) : null}

              {lbDetails?.state === State.Open &&
                lbDetails.category === 'PAID' && <span>Entry Fee</span>}

              {lbDetails?.state === State.ResultDeclared ||
              lbDetails?.state === State.ComputingResult ? (
                <span>Leaderboard Date</span>
              ) : null}
            </div>
            <div
              className={styles.cta}
              style={
                lbDetails?.type === LbType.GoalBased.toString() &&
                lbDetails?.state !== State.Live
                  ? { paddingBottom: 20 }
                  : {}
              }
            >
              <div className={styles.time}>
                {lbDetails?.category !== 'PAID' &&
                lbDetails?.state === State.Open ? (
                  <Countdown eventTime={new Date(lbDetails.live_time)} />
                ) : null}
                {(lbDetails?.type === LbType.RankBased.toString() ||
                  lbDetails?.type === LbType.GoalBased.toString()) &&
                lbDetails?.state === State.Live ? (
                  <Countdown eventTime={new Date(lbDetails.close_time)} />
                ) : null}
                {lbDetails?.state === State.ResultDeclared ||
                lbDetails?.state === State.ComputingResult ? (
                  <span>{dayjs(lbDetails.live_time).format('ll')}</span>
                ) : null}

                {lbDetails?.state === State.Open &&
                lbDetails.category === 'PAID'
                  ? getPaidLbEntryFeeHtml()
                  : null}
              </div>
              {lbDetails?.state === State.Live &&
                !showPayNowButton() &&
                !showOptInButton() && (
                  <a
                    className={styles.play}
                    href={generateCTA(lbDetails.filters)}
                    onClick={(): void => {
                      sendPlayNowClickEvent('Top Play Now button')
                    }}
                  >
                    Play Now
                  </a>
                )}

              {showPayNowButton() && (
                <button
                  type="button"
                  className={styles.play}
                  onClick={(): void => {
                    setShowPurchaseCard(true)
                    sendJoinNowClickEvent('Top Pay Now button')
                  }}
                >
                  Pay Now
                </button>
              )}

              {showOptInButton() && (
                <button
                  type="button"
                  className={styles.play}
                  onClick={(): void => {
                    setShowOptInCard(true)
                    sendJoinNowClickEvent('Top Join Now button')
                  }}
                >
                  Join Now
                </button>
              )}
            </div>
            {lbDetails?.type === LbType.GoalBased.toString() &&
              lbDetails.state !== State.Open && (
                <GoalStepper
                  prize_distribution={lbDetails?.prize_distribution}
                  setSelectedGoal={setSelectedGoal}
                  selectedGoal={selectedGoal}
                  entries={entries}
                  state={lbDetails.state}
                />
              )}
          </div>

          {lbDetails && <PrizeInfo lbDetails={lbDetails} entries={entries} />}

          {(lbDetails?.type === LbType.RankBased.toString() &&
            lbDetails.state !== State.Open) ||
          lbDetails?.type === 'Paid' ? (
            <div style={{ padding: '0 16px 16px' }}>
              <CurrentRank lbDetails={lbDetails} entries={entries} />
            </div>
          ) : null}

          {lbDetails?.type === LbType.GoalBased.toString() &&
            lbDetails.state !== State.Open && (
              <div style={{ padding: '0 16px 16px' }}>
                <CurrentGoal
                  lbDetails={lbDetails}
                  entries={entries}
                  selectedGoal={selectedGoal}
                />
              </div>
            )}

          {lbDetails ? (
            <div style={{ padding: ' 0 0 0 0' }}>
              <LeaderboardAndSummary
                lbDetails={{
                  ...lbDetails,
                  leaderboard_id: leaderboardId || '',
                }}
                entries={entries}
                summary={entrySummary}
              />
            </div>
          ) : null}

          {lbDetails ? (
            <div style={{ padding: '0 16px' }}>
              <LeaderboardRules
                lbDetails={{
                  ...lbDetails,
                  leaderboard_id: leaderboardId || '',
                }}
              />
            </div>
          ) : null}

          {lbDetails?.metric === 'PokerHand' ? (
            <div style={{ padding: '0 16px' }}>
              <HowToWin
                lbDetails={{
                  ...lbDetails,
                  leaderboard_id: leaderboardId || '',
                }}
              />
            </div>
          ) : null}

          {lbDetails?.state === State.Live &&
            !showPayNowButton() &&
            !showOptInButton() && (
              <div>
                <a
                  className={styles.playBlack}
                  href={generateCTA(lbDetails.filters)}
                  onClick={(): void => {
                    sendPlayNowClickEvent('Middle Play Now button')
                  }}
                >
                  Play Now
                </a>
              </div>
            )}
          {showPayNowButton() && (
            <button
              type="button"
              className={styles.playBlack}
              onClick={(): void => {
                setShowPurchaseCard(true)
                sendJoinNowClickEvent('Middle Join Now button')
              }}
            >
              Join Now
            </button>
          )}

          {showOptInButton() && (
            <button
              type="button"
              className={styles.playBlack}
              onClick={(): void => {
                setShowOptInCard(true)
                sendJoinNowClickEvent('Middle Join Now button')
              }}
            >
              Join Now
            </button>
          )}

          <div style={{ background: 'black' }}>
            <PreviousWinners previousWinners={config.previous_winners} />
            <Banners banners={config.banners} />
            <Testimonials
              testimonials={config.testimonials}
              lbId={leaderboardId || ''}
            />

            {lbDetails && (
              <div style={{ padding: '16px 16px 16px 0', background: 'black' }}>
                <GeneralRules lb={lbDetails} />
              </div>
            )}
          </div>

          {lbDetails?.state === State.Live &&
            !showPayNowButton() &&
            !showOptInButton() && (
              <div style={{ background: 'black', paddingBottom: '20px' }}>
                <a
                  className={styles.playWhite}
                  href={generateCTA(lbDetails.filters)}
                  onClick={(): void => {
                    sendPlayNowClickEvent('Bottom Play Now button')
                  }}
                >
                  Play Now
                </a>
              </div>
            )}
          {showPayNowButton() ? (
            <div style={{ background: 'black', paddingBottom: '20px' }}>
              <button
                type="button"
                className={styles.playWhite}
                onClick={(): void => {
                  setShowPurchaseCard(true)
                  sendJoinNowClickEvent('Bottom Join Now button')
                }}
              >
                Join Now
              </button>
            </div>
          ) : null}

          {showOptInButton() && (
            <div style={{ background: 'black', paddingBottom: '20px' }}>
              <button
                type="button"
                className={styles.playWhite}
                onClick={(): void => {
                  setShowOptInCard(true)
                  sendJoinNowClickEvent('Bottom Join Now button')
                }}
              >
                Join Now
              </button>
            </div>
          )}

          {lbDetails?.state === State.Live &&
            !showPayNowButton() &&
            !showOptInButton() && (
              <StickyFooter
                userScore={lbDetails?.player_score}
                firstRankerScore={firstRankerScore}
                deeplink={generateCTA(lbDetails.filters)}
                sendClickEvent={sendPlayNowClickEvent}
                lbDetails={lbDetails}
              />
            )}

          {showPurchaseCard && (
            <PurchaseCard
              entryFee={
                lbDetails?.entry_fee?.amount
                  ? Math.ceil(lbDetails?.entry_fee?.amount)
                  : 0
              }
              setShowPurchaseCard={setShowPurchaseCard}
              lbId={leaderboardId || ''}
              lbName={lbDetails?.external_name || ''}
              fetchLeaderboardDetails={fetchLeaderboardDetails}
              offer={
                !!lbDetails?.offers && lbDetails.offers.length > 0
                  ? lbDetails.offers[0]
                  : undefined
              }
            />
          )}

          {showOptInCard && (
            <OptInCard
              setShowOptInCard={setShowOptInCard}
              lbId={leaderboardId || ''}
              lbName={lbDetails?.external_name || ''}
              fetchLeaderboardDetails={fetchLeaderboardDetails}
            />
          )}
        </div>
      )}
    </>
  )
}

export default LeaderboardDetails
