import React from 'react'
import styles from './GeneralRules.module.scss'
import { LeaderboardDetails } from '../../types/leaderboard-details'

interface Props {
  lb: LeaderboardDetails
}

const GeneralRules: React.FC<Props> = ({ lb }) => {
  return (
    <div className={styles.GeneralRules}>
      <div className={styles.pageTitle}>{lb.ui_data.tnc.header}</div>
      <ol>
        {lb.ui_data.tnc.points.map(p => {
          return <li key={p}>{p}</li>
        })}
        {/* <li>
          Prize are defined as the amount you win by playing any contests of
          Trivia, Casual or Cards.
        </li>
        <li>
          Whoever has highest Prize in the contests from 24th (Fri, 19:34:00) to
          31st (Fri, 23:59:59) January, 2020 wins ₹4000.
        </li>
        <li>Users can join contests using Cash or Passes.</li>
        <li>
          Pro tip: You can play as many FREE poker contests as possible, to
          learn the game. Once you become confident, play the big league with
          real money. Win contest, Win real cash (Prize). Rise to the top of
          leaderboard, to win Big Money.
        </li>
        <li>Get free passes by referring your friends on GetMega.</li>
        <li>
          In case of a tie for top slot, total contest joins will be counted.
          The tiebreaker will be decided by least number of contest joins used
          to enter a contest.
        </li>
        <li>
          This tournament is open to Indian citizens above 18 years of age, who
          have a GetMega account. This tournament is not open to users from
          Assam, Nagaland, Odisha, Sikkim, & Telangana.
        </li>
        <li>
          If any user is found gaming the system, he/she will be banned from
          this tournament and from GetMega. The final decision regarding the
          tournament winner lies with GetMega.
        </li>
        <li>
          Within 24 hours of the end of the tournament, our officials will get
          in touch with the winner on the number provided to GetMega during
          signup.
        </li>
        <li>
          No officials / employees / interns of GetMega are eligible for this
          contest.
        </li> */}
      </ol>
    </div>
  )
}

export default GeneralRules
