import React from 'react'
import dayjs from 'dayjs'

import styles from './DetailsCard.module.scss'
import InfoIcon from '../../../assets/images/info.png'
import { PropertyDetails } from '../../../types/property-details'

interface Props {
  propertyDetails: PropertyDetails
}

const DetailsCard: React.FC<Props> = ({ propertyDetails }) => {
  return (
    <div id={styles.DetailsCard}>
      <div className={styles.game}>
        <div className={styles.text}>
          <div className={styles.name}>{propertyDetails?.external_name}</div>
          <div className={styles.other}>Show off your skill and win prizes</div>
        </div>
      </div>

      <div className={styles.separator} />

      <div className={styles.timing}>
        <img src={InfoIcon} alt="info" />
        <div>
          Total Prize pool is ₹
          {propertyDetails?.prize_distribution?.total_prize.toLocaleString(
            'en-IN'
          )}
        </div>
      </div>

      {propertyDetails?.schedule ? (
        <>
          <div className={styles.separator} />

          <div className={styles.bb}>
            <img src={InfoIcon} alt="info" />
            <div className={styles.times}>
              <div>
                <span>Starts at </span>
                <span className={styles.b}>
                  {dayjs(propertyDetails.schedule.start_time).format('llll')}
                </span>
              </div>
              <div>
                <span>Ends at </span>
                <span className={styles.b}>
                  {dayjs(propertyDetails.schedule.end_time).format('llll')}
                </span>
              </div>
            </div>
          </div>
        </>
      ) : null}
    </div>
  )
}

export default DetailsCard
