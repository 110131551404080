// @ts-ignore
import Carousel from 'infinite-react-carousel'
import React from 'react'
import styles from './TopBanners.module.scss'
import { Banner } from '../../types/leaderboard-details'
import { generateImageUrl } from '../../helpers/url-generator'

interface Props {
  banners: Banner[]
}

const TopBanners: React.FC<Props> = ({ banners }) => {
  return (
    <Carousel
      dots
      arrows={false}
      adaptiveHeight
      className={styles['infinite-carousel']}
      duration={500}
    >
      {banners.map(b => (
        <a className={styles['banner-carousel']} key={b.image_url}>
          <img
            className={styles['bannerImage']}
            src={generateImageUrl(b.image_url)}
            alt="contestant"
          />
        </a>
      ))}
    </Carousel>
  )
}

export default TopBanners
