import React from 'react'
import dayjs from 'dayjs'

import styles from './LeaderboardRules.module.scss'
import PokerLogo from '../../assets/images/poker.png'
import InfoIcon from '../../assets/images/info.png'
import { LeaderboardDetails } from '../../types/leaderboard-details'
import CollapsebaleSection from '../CollapseableSection/CollapseableSection'
import { MetricFieldTournamentConfig } from '../../helpers/constants'
import { MetricFilter, LbType } from '../../types/leaderboard'

interface Props {
  lbDetails: LeaderboardDetails
}

const LeaderboardRules: React.FC<Props> = ({ lbDetails }) => {
  const metricFilter =
    lbDetails.filters.find(
      f =>
        f.metric_field.toString() === 'Bigblind' ||
        f.metric_field.toString() === 'PointRate'
    ) || ({} as MetricFilter)
  const { number_min: numberMin, number_max: numberMax } =
    metricFilter.value_range
  const metricField = metricFilter.metric_field
  const gameNameMF =
    lbDetails.filters.find(f => f.metric_field.toString() === 'GameName') ||
    ({} as MetricFilter)
  const gameName = gameNameMF.value_range
    ? gameNameMF.value_range.string_value
    : 'Poker'

  const sendLeaderBoardRulesButtonClickedEvent = (): void => {
    try {
      window.analytics.track('LeaderBoardRulesButtonClicked', {
        leaderboard_id: lbDetails.leaderboard_id,
        source_screen: 'My leaderboard detail',
      })
    } catch (error) {
      console.error(error)
    }
    try {
      window.rudderanalytics.track('LeaderBoardRulesButtonClicked', {
        leaderboard_id: lbDetails.leaderboard_id,
        source_screen: 'My leaderboard detail',
      })
    } catch (error) {
      console.error(error)
    }
  }

  function getMinQualificationScore() {
    if (!lbDetails) return 0
    if (
      lbDetails.type === LbType.GoalBased.toString() &&
      lbDetails.prize_distribution?.goal_based?.length > 0
    ) {
      return Math.min(
        ...lbDetails.prize_distribution.goal_based.map(gb => gb.winning_score)
      )
    } else if (
      lbDetails.type === LbType.RankBased.toString() &&
      lbDetails.prize_distribution?.rank_based?.length > 0
    ) {
      return (
        lbDetails.prize_distribution.rank_based[
          lbDetails.prize_distribution.rank_based.length - 1
        ].qualification_score || 0
      )
    }
    return 0
  }

  return (
    <div id={styles.LeaderboardRules}>
      <CollapsebaleSection
        title="Leaderboard Rules"
        contentClassName={styles['cs-content']}
        onHeaderClick={sendLeaderBoardRulesButtonClickedEvent}
      >
        <div className={styles.game}>
          <div className={styles.logo}>
            <img src={PokerLogo} alt="poker" />
          </div>
          <div className={styles.text}>
            <div className={styles.name}>{gameName} only Contest</div>
            <div className={styles.other}>
              Other game scores will not be considered
            </div>
          </div>
        </div>

        <div className={styles.separator} />

        <div className={styles.timing}>
          <img src={InfoIcon} alt="info" />
          <div>
            <div>
              <span>Starts at </span>
              <span className={styles.b}>
                {dayjs(lbDetails.live_time).format('llll')}
              </span>
            </div>
            <div>
              <span>Ends at </span>
              <span className={styles.b}>
                {dayjs(lbDetails.close_time).format('llll')}
              </span>
            </div>
          </div>
        </div>

        <div className={styles.separator} />

        <div className={styles.bb}>
          <img src={InfoIcon} alt="info" />
          <div>
            <span>Scoring is only applicable on tournaments with </span>
            <span className={styles.b}>
              {MetricFieldTournamentConfig[metricField]} ₹{numberMin}{' '}
              {numberMin !== numberMax && `- ₹${numberMax}`}
            </span>
          </div>
        </div>

        <div className={styles.separator} />

        <div className={styles.minScore}>
          <img src={InfoIcon} alt="info" />
          <div>
            <span>You should score min </span>
            <span className={styles.b}>{getMinQualificationScore()}</span>
            <span> to qualify for any cash prize</span>
          </div>
        </div>

        <div className={styles.separator} />

        <div className={styles.tds}>
          <img src={InfoIcon} alt="info" />
          <div>
            <span>
              As per govt. mandate, 31.72% TDS will be deducted from all cash
              prizes above
            </span>
            <span className={styles.b}> ₹10000</span>
          </div>
        </div>
      </CollapsebaleSection>
    </div>
  )
}

export default LeaderboardRules
