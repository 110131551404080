import axios from 'axios'
import { Leaderboard } from '../../types/leaderboard'
import getAuthToken, { getAppFlavour } from '../../auth-token'

export const getLeaderboards = async (
  d: string,
  propertyId?: string
): Promise<{
  leaderboards: Leaderboard[]
  error: string
}> => {
  const url = `${process.env.REACT_APP_MAPI_HOST_URI}/twirp/mapi.pb.ms.v1.LeaderboardsService/List`
  const data: any = {
    date: d,
  }

  if (propertyId) {
    data.property_id = propertyId
  }
  const token = getAuthToken()

  return axios({
    method: 'POST',
    url,
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`,
      'x-app-flavor': getAppFlavour(),
    },
    data,
  })
    .then(response => {
      // console.log(response.data)
      return {
        leaderboards: response.data.leaderboards || [],
        error: '',
      }
    })
    .catch(error => {
      console.error(error)
      return {
        leaderboards: [],
        error: 'Error fetching list of leaderboards',
      }
    })
}

export const getMyLeaderboards = async (): Promise<{
  leaderboards: Leaderboard[]
  error: string
}> => {
  const url = `${process.env.REACT_APP_MAPI_HOST_URI}/twirp/mapi.pb.ms.v1.LeaderboardsService/MyLeaderboards`
  const data = {}
  const token = getAuthToken()

  return axios({
    method: 'POST',
    url,
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`,
      'x-app-flavor': getAppFlavour(),
    },
    data,
  })
    .then(response => {
      // console.log(response.data)
      return {
        leaderboards: response.data.leaderboards || [],
        error: '',
      }
    })
    .catch(error => {
      console.error(error)
      return {
        leaderboards: [],
        error: 'Error fetching list of leaderboards',
      }
    })
}
