import axios from 'axios'
import getAuthToken from '../../auth-token'
import { EntrySummary } from '../../types/leaderboard-entry'
// import { LeaderboardDetails } from '../../types/leaderboard-details'

export const getLeaderboardDetails = async (lbId: string): Promise<any> => {
  const url = `${process.env.REACT_APP_MAPI_HOST_URI}/twirp/mapi.pb.ms.v1.LeaderboardsService/Details`
  const data = {
    leaderboard_id: lbId,
  }
  const token: string = getAuthToken()

  return axios({
    method: 'POST',
    url,
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`,
    },
    data,
  })
    .then(response => {
      return {
        details: response.data,
        error: '',
      }
    })
    .catch(error => {
      console.error(error)
      return {
        details: undefined,
        error: 'Error fetching details',
      }
    })
}

export const getEntrySummary = async (
  leaderboardId: string
): Promise<{
  summary: EntrySummary[]
  error: string
}> => {
  const url = `${process.env.REACT_APP_MAPI_HOST_URI}/twirp/mapi.pb.ms.v1.LeaderboardsService/EntrySummary`
  const data = {
    leaderboard_id: leaderboardId,
    count: 10,
    offset: 0,
  }
  const token: string = getAuthToken()

  return axios({
    method: 'POST',
    url,
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`,
    },
    data,
  })
    .then(response => {
      return {
        summary: response.data.entries_summary,
        error: '',
      }
    })
    .catch(error => {
      console.error(error)
      return {
        summary: [],
        error: 'Error fetching details',
      }
    })
}

export const getWalletBalance = async (): Promise<any> => {
  const url = `${process.env.REACT_APP_MAPI_HOST_URI}/twirp/mapi.pb.ms.v1.PlayerService/WalletBalance`
  const token: string = getAuthToken()
  const data = {}
  return axios({
    method: 'POST',
    url,
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`,
    },
    data,
  })
    .then(response => {
      console.log(response.data)
      return {
        balance: response.data.balance || 0,
        error: '',
      }
    })
    .catch(error => {
      console.error(error)
      return {
        balance: undefined,
        error: 'Error fetching balance',
      }
    })
}

export const purchaseLbEntry = async (
  leaderboard_id: string,
  offer_id: string
): Promise<any> => {
  const url = `${process.env.REACT_APP_MAPI_HOST_URI}/twirp/mapi.pb.ms.v1.LeaderboardsService/PurchaseLeaderboardEntry`
  const token: string = getAuthToken()
  const data = {
    leaderboard_id,
    offer_id,
  }
  return axios({
    method: 'POST',
    url,
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`,
    },
    data,
  })
    .then(response => {
      console.log(response.data)
      return {
        balance: response.data,
        error: '',
      }
    })
    .catch(error => {
      console.error(error)
      return {
        balance: undefined,
        error: 'Error purchasing entry',
      }
    })
}

export const joinLeaderboard = async (leaderboard_id: string): Promise<any> => {
  const url = `${process.env.REACT_APP_MAPI_HOST_URI}/twirp/mapi.pb.ms.v1.LeaderboardsService/JoinLeaderboard`
  const token: string = getAuthToken()
  const data = {
    leaderboard_id,
  }
  return axios({
    method: 'POST',
    url,
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`,
    },
    data,
  })
    .then(response => {
      console.log(response.data)
      return {
        error: '',
      }
    })
    .catch(error => {
      console.error(error)
      return {
        error: 'Error purchasing entry',
      }
    })
}

export default getLeaderboardDetails
