import React from 'react'
import styles from './StickyFooter.module.scss'
// @ts-ignore
import Progress from 'react-progressbar'
import { LeaderboardDetails } from '../../types/leaderboard-details'
import { calculateCurrentGoal } from '../../helpers/constants'
import { LbType } from '../../types/leaderboard'

interface Props {
  deeplink?: string
  userScore?: number
  firstRankerScore?: number
  sendClickEvent(btnLocation: string): void
  lbDetails: LeaderboardDetails
}

const StickyFooter = (props: Props) => {
  const stickyClassName = !!props.userScore
    ? `${styles['sticky-play-now']}  ${styles['sticky-user-data']}`
    : styles['sticky-normal']

  let curentGoal = 0
  if (props.lbDetails.type === LbType.GoalBased.toString()) {
    curentGoal = calculateCurrentGoal(
      props.lbDetails.prize_distribution,
      props.userScore || 0
    )
  }

  return (
    <div className={stickyClassName}>
      {!!props.userScore && !!props.firstRankerScore ? (
        <>
          <div className={styles.userWinningInfo}>
            {props.lbDetails.type === LbType.RankBased.toString() && (
              <span className={styles.bestPlay}>
                Score ₹
                {(
                  parseInt(props.firstRankerScore.toString(), 10) + 1
                ).toLocaleString('en-IN')}{' '}
                for 1st prize
              </span>
            )}

            {props.lbDetails.type === LbType.GoalBased.toString() && (
              <span className={styles.bestPlay}>
                {curentGoal >
                props.lbDetails.prize_distribution.goal_based.length
                  ? 'Goals Completed'
                  : `Goal ${curentGoal}`}
              </span>
            )}

            <span className={styles.firstInfo}>
              Your Winnings ₹{props.userScore.toLocaleString('en-IN')}
            </span>
            {props.lbDetails.type === LbType.RankBased.toString() && (
              <div className={styles.progressDiv}>
                <Progress
                  completed={(props.userScore / props.firstRankerScore) * 100}
                  color="#728EE3"
                  height={5}
                />
              </div>
            )}
          </div>
          <a
            href={props.deeplink}
            className={`${styles.playButton} ${styles.playButtonExtra}`}
            style={{ textDecoration: 'none', color: 'black' }}
            onClick={(): void => {
              props.sendClickEvent('Sticky Play Now button')
            }}
          >
            Play Now
          </a>
        </>
      ) : (
        <a
          href={props.deeplink}
          className={styles.playButton}
          style={{ textDecoration: 'none', color: 'black' }}
          onClick={(): void => {
            props.sendClickEvent('Sticky Play Now button')
          }}
        >
          Play Now
        </a>
      )}
    </div>
  )
}

export default StickyFooter
