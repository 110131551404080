import React, { useEffect, useState } from 'react'
import Loader from 'react-loader-spinner'
import styles from './OptIn.module.scss'
import { joinLeaderboard } from '../../containers/LeaderboardDetails/api'
import CloseIcon from '../../assets/images/close.png'
import TickIcon from '../../assets/images/tick.png'
import 'react-loader-spinner/dist/loader/css/react-spinner-loader.css'

interface Props {
  setShowOptInCard: Function
  lbId: string
  lbName: string
  fetchLeaderboardDetails: Function
}

const PurchaseCard: React.FC<Props> = ({
  setShowOptInCard,
  lbId,
  lbName,
  fetchLeaderboardDetails,
}) => {
  const [err, setErr] = useState('')
  const [loading, setLoading] = useState(true)

  const closePurchaseCard = () => {
    setShowOptInCard(false)
  }

  useEffect(() => {
    const optInLeaderboard = async (lbId: string) => {
      setLoading(true)
      const resp = await joinLeaderboard(lbId)
      setLoading(false)
      if (!!resp.error) {
        setErr(resp.error)
      }
    }
    optInLeaderboard(lbId)
  }, [lbId])

  if (loading) {
    return (
      <div id={styles.PurchaseCard}>
        <div className={styles.bottomCard} style={{ alignItems: 'center' }}>
          <div className={styles.loader}>
            <Loader type="Oval" color="#000000" height={100} width={100} />
          </div>
        </div>
      </div>
    )
  }

  return (
    <div id={styles.PurchaseCard}>
      <div className={styles.bottomCard}>
        {err ? (
          <>
            <div
              className={styles.header}
              style={{ justifyContent: 'flex-end' }}
            >
              <img
                className={styles.crossIcon}
                src={CloseIcon}
                onClick={closePurchaseCard}
                alt="close"
              />
            </div>
            <span className={styles.err}>{err}</span>
          </>
        ) : (
          <>
            <div
              className={styles.header}
              style={{ justifyContent: 'flex-end' }}
            >
              <img
                className={styles.crossIcon}
                src={CloseIcon}
                onClick={closePurchaseCard}
                alt="close"
              />
            </div>
            <div className={styles.body} style={{ alignItems: 'center' }}>
              <img className={styles.tick} src={TickIcon} alt="tick" />
              <span className={styles.success}>
                Yey! You are now part of the{' '}
                <span className={styles.lbName}>{lbName}</span>! Keep playing
                and keep winning
              </span>
            </div>
            <a
              className={styles.payNow}
              onClick={() => {
                fetchLeaderboardDetails(lbId)
                setShowOptInCard(false)
              }}
            >
              Go Back
            </a>
          </>
        )}
      </div>
    </div>
  )
}

export default PurchaseCard
