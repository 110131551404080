import getAuthToken from '../../auth-token'
import axios from 'axios'
import { Property } from '../../types/property'

export const getPropertyList = async (
  d: string
): Promise<{
  properties: Property[]
  error: string
}> => {
  const url = `${process.env.REACT_APP_MAPI_HOST_URI}/twirp/mapi.pb.ms.v1.PropertiesService/ListProperties`
  const data = {}
  // TODO: Remove hard coded token
  const token = getAuthToken()

  return axios({
    method: 'POST',
    url,
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`,
    },
    data,
  })
    .then(response => {
      // console.log(response.data)
      return {
        properties: response.data.properties || [],
        error: '',
      }
    })
    .catch(error => {
      console.error(error)
      return {
        properties: [],
        error: 'Error fetching list of properties',
      }
    })
}

export const getPropertyDetails = (propertyId: string) => {
  const url = `${process.env.REACT_APP_MAPI_HOST_URI}/twirp/mapi.pb.ms.v1.PropertiesService/GetProperty`
  const data = {
    property_id: propertyId,
  }
  const token: string = getAuthToken()

  return axios({
    method: 'POST',
    url,
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`,
    },
    data,
  })
    .then(response => {
      return {
        details: response.data,
        error: '',
      }
    })
    .catch(error => {
      console.error(error)
      return {
        details: undefined,
        error: 'Error fetching details',
      }
    })
}
