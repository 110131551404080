import React, { useEffect, useState } from 'react'
import styles from './PropertyBannerList.module.scss'
import PropertyCard from '../../components/PropertyCard/PropertyCard'
import { generateImageUrl } from '../../helpers/url-generator'
import { Property } from '../../types/property'
import { getPropertyList } from './api'
import dayjs from 'dayjs'
import { useDate } from '../LeaderboardsList/DateContext'
import { bannerIndexTypeMap } from '../../helpers/constants'

const PropertyBannerList = () => {
  const [properties, setProperties] = useState<Property[]>([])
  const { date } = useDate()!

  const fetchProperties = async (): Promise<void> => {
    const { properties } = await getPropertyList(date.format())

    setProperties(properties)
  }

  useEffect(() => {
    fetchProperties()
  }, [])

  const getTypeForBanner = (i: number) => {
    let index = bannerIndexTypeMap[properties.length]
    if (!!index) {
      return index[i]
    }
    return { type: 'large', isLast: false }
  }

  return (
    <div id={styles.PropertyBannerList}>
      {properties.map((p, i) => {
        let { type, isLast } = getTypeForBanner(i)
        return (
          <PropertyCard
            id={p.property_id}
            name={p.external_name}
            state={p.state}
            startTime={dayjs(p.schedule.start_time).format('D MMM')}
            endTime={dayjs(p.schedule.end_time).format('D MMM')}
            imageUrl={generateImageUrl(p.banners.large_image_url)}
            type={type}
            isLast={isLast}
          />
        )
      })}
    </div>
  )
}

export default PropertyBannerList
