import React from 'react'
import styles from './Leaderboard.module.scss'
import { LeaderboardDetails } from '../../types/leaderboard-details'
import { State, LbType } from '../../types/leaderboard'
import { LeaderboardEntry } from '../../types/leaderboard-entry'
import { getPlayerId } from '../../auth-token'
import { generateProfileUrl } from '../../helpers/url-generator'

interface Props {
  lb: LeaderboardDetails
  entries: LeaderboardEntry[]
  pdRef: React.RefObject<HTMLDivElement> | null
}

const Leaderboard: React.FC<Props> = ({ lb, entries, pdRef }) => {
  // show score/amount won based on leaderboard state
  const RenderScoreOrAmount = ({
    prize,
    score,
    comment,
  }: {
    prize: number
    score: number
    comment: string
  }): React.ReactElement => {
    return (
      <>
        {lb.state !== State.ResultDeclared ? (
          <div className={styles.score}>
            {score || '0'}
            {(parseFloat(`${score}`) || 0) <
            (parseFloat(
              `${
                !!lb.prize_distribution.rank_based
                  ? lb.prize_distribution.rank_based[
                      lb.prize_distribution.rank_based.length - 1
                    ].qualification_score
                  : '0'
              }`
            ) || 0) ? (
              <sup className={styles.red}>*</sup>
            ) : null}
          </div>
        ) : (
          <div
            className={styles.score}
            style={{ color: prize > 0 ? 'black' : '#ED4F46' }}
          >
            {prize > 0 ? `₹${prize}` : comment}
          </div>
        )}
      </>
    )
  }

  const myPlayerId = getPlayerId()

  const time_convert = (num: number) => {
    var hours = Math.floor(num / 60)
    var minutes = Math.ceil(num % 60)

    if (hours > 0) {
      if (!isNaN(hours) && !isNaN(minutes)) {
        return `${hours}h ${minutes}m`
      }
    }

    return !isNaN(minutes) ? `${minutes}m` : `1m`
  }

  return (
    <div id={styles.Leaderboard}>
      {lb.state === State.ComputingResult ||
      lb.state === State.ResultDeclared ? (
        <div className={styles.pageTitle}>Winning Distribution</div>
      ) : null}

      <div
        style={
          lb.state === State.ComputingResult
            ? { opacity: 0.12 }
            : { opacity: 1 }
        }
      >
        <div className={styles.list}>
          {entries.map((p, i) => {
            return (
              <React.Fragment key={p.player.player_id}>
                <div className={styles.rank}>
                  {lb.type === LbType.GoalBased.toString()
                    ? `${i + 1}`
                    : p.rank || '-'}
                </div>
                <img
                  className={styles.dp}
                  src={generateProfileUrl(p.player.photo_url)}
                  alt={p.player.name}
                />
                <div className={styles.player}>
                  <div
                    className={`${styles.name} ${
                      p.player.player_id === myPlayerId ? styles.You : ''
                    }`}
                  >
                    {p.player.player_id === myPlayerId ? 'You' : p.player.name}
                  </div>
                  {lb.state !== State.ResultDeclared && (
                    <div className={styles.lastActive}>
                      Last active {time_convert(p.player.last_active)} ago{' '}
                    </div>
                  )}
                  {lb.state === State.ResultDeclared && (
                    <div className={styles.lastActive}>
                      <span>Score: </span>
                      <span>{p.score || '0'}</span>
                    </div>
                  )}
                </div>
                <RenderScoreOrAmount
                  prize={p.prize}
                  score={p.score}
                  comment={p.comment}
                />
              </React.Fragment>
            )
          })}

          {entries.length === 0 && (
            <div style={{ gridColumn: '1/-1', color: '#ED4F46' }}>
              No Players Joined
            </div>
          )}
        </div>

        {lb.player_score &&
        lb.player_score > 0 &&
        !entries.find(e => e.player.player_id === myPlayerId) ? (
          <div className={styles.loggedInPlayer}>
            <div className={styles.rank}>{lb.player_rank}</div>
            <div className={styles.dp} />
            <div className={styles.player}>
              <div className={`${styles.name} ${styles.You}`}>You</div>
              {lb.state === State.Live &&
              lb.type === LbType.RankBased.toString() &&
              lb.player_score <
                lb.prize_distribution.rank_based[
                  lb.prize_distribution.rank_based.length - 1
                ].qualification_score ? (
                <div className={styles.minScore}>
                  <span>Score min </span>
                  {
                    lb.prize_distribution.rank_based[
                      lb.prize_distribution.rank_based.length - 1
                    ].qualification_score
                  }
                  <span> for cash prize</span>
                </div>
              ) : null}
              {lb.state === State.ResultDeclared ? (
                <div className={styles.lastActive}>
                  <span>Score: </span>
                  <span>{lb.player_score?.toFixed(2).replace('.00', '')}</span>
                </div>
              ) : null}
            </div>
            <RenderScoreOrAmount prize={0} score={lb.player_score} comment="" />
          </div>
        ) : null}

        {lb.state !== State.ComputingResult &&
        lb.state !== State.ResultDeclared ? (
          <div className={styles.minScore} style={{ padding: '0 16px' }}>
            <span>
              * These users have to create a minimum score to qualify for cash
              prizes. See Prize Distribution Table to&nbsp;
            </span>
            <button
              type="button"
              onClick={(): void => {
                if (pdRef)
                  window.scrollTo({
                    top: pdRef.current?.offsetTop || 0,
                    behavior: 'smooth',
                  })
              }}
            >
              Learn more.
            </button>
          </div>
        ) : null}
      </div>

      {lb.state === State.ComputingResult ? (
        <div className={styles.overlay}>Final Leaderboard will appear here</div>
      ) : null}
    </div>
  )
}

export default Leaderboard
