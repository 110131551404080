import { StringLiteral } from 'typescript'

export interface Leaderboard {
  state: State
  external_name: string
  open_time: Date
  close_time: Date
  live_time: Date
  leaderboard_id: string
  total_participants: number
  type: string
  category?: string
  player_rank: number
  player_score: number
  filters: MetricFilter[]
  prize_distribution: PrizeDistribution
  entry_fee?: EntryFee
  offers?: Offer[]
  image_url?: string
  total_prize_amount: number
  default_sort_index?: number
  has_non_cash_prizes?: boolean
}

export interface MetricFilter {
  metric_field: MetricField
  field_type: FieldType
  value_range: ValueRange
}

export interface EntryFee {
  amount: number
  slots: number
}

export interface Offer {
  offer_id: string
  reward: Reward
}

export interface Reward {
  Amount: number
  MaxAmount: number
  PercentValue: number
}

export enum LbType {
  GoalBased = 'GoalBased',
  RankBased = 'RankBased',
}

export enum MetricField {
  InvalidMetricField = 'InvalidMetricField',
  Bigblind = 'Bigblind',
  PointRate = 'PointRate',
  GameName = 'GameName',
  GameCategory = 'GameCategory',
}

export enum FieldType {
  InvalidFieldType = 0,
  TIME = 1,
  NUMBER = 2,
  BOOLEAN = 3,
  STRING = 4,
}

export const WalletTypes: { [key: string]: string } = {
  DEPOSIT: 'Deposit',
  BONUS: 'Bonus',
}

export const DefaultWalletType = WalletTypes.DEPOSIT

export interface ValueRange {
  time_min?: string
  time_max?: string
  number_min?: number
  number_max?: number
  boolean_value?: boolean
  string_value?: string
}

export enum State {
  Draft = 'Draft',
  Open = 'Open',
  Live = 'Live',
  ComputingResult = 'ComputingResult',
  ResultDeclared = 'ResultDeclared',
  Visible = 'Visible',
}

export interface PrizeDistribution {
  type: string
  rank_based: RankBased[]
  goal_based: GoalBased[]
}

export interface RankBased {
  start_rank: number
  end_rank: number
  prize: Prize
  qualification_score: number
}

export enum PrizeType {
  INSTANT = 'INSTANT',
  STAGGERED = 'STAGGERED',
}

export interface Description {
  title: string
  sub_title: string
}

export interface IconInfo {
  icon_url: string
  below_icon_text: string
}

export interface RewardInfo {
  amount: number
}

export interface SubTasks {
  description: Description
  icon_info: IconInfo
  reward_info: RewardInfo
}

export interface TaskUiInfo {
  subtasks: SubTasks[]
  task_template_id: string
  title: string
  sub_title: string
  reward_info_title: string
}

export interface Prize {
  winning_amount: number
  display_value: string
  wallet_type: string
  prize_type?: PrizeType
  task_ui_info?: TaskUiInfo
}

export interface GoalBased {
  goal: number
  goal_name: string
  prize: Prize
  winning_score: number
}
