import React from 'react'
import styles from './PropertyCard.module.scss'
import Arrow from '../../assets/images/arrow-right.png'
import { navigate } from '@reach/router'

interface Props {
  name: string
  state: string
  startTime: string
  endTime: string
  imageUrl: string
  id: string
  type: string
  isLast: boolean
}

const PropertyCard: React.FC<Props> = props => {
  const { name, state, startTime, endTime, imageUrl, type, isLast } = props

  let getContainerWidth = (type: string) => {
    switch (type) {
      case 'large':
        return '100%'
      case 'medium':
        return 'calc(50% - 6px)'
      case 'small':
        return 'calc(25% - 9px)'
    }
    return '100%'
  }

  return (
    <div
      className={styles.container}
      style={{
        width: getContainerWidth(type),
        marginRight: isLast ? 0 : 12,
        marginTop: 10,
        marginBottom: 10,
        height: 'auto',
      }}
      onClick={() => {
        navigate(`/property-details/${props.id}`)
      }}
    >
      <p
        className={styles.state}
        style={{ color: state === 'Visible' ? '#FBD64A' : '#32e0c7' }}
      >
        {state === 'Visible' ? 'Upcoming' : state}
      </p>
      <img className={styles.imageUrl} src={imageUrl} alt="Property Hero" />
      <div
        className={styles.bottomContainer}
        style={{
          marginLeft: type === 'small' ? 8 : 10,
          marginTop: type === 'small' ? 6 : 2,
          height: type === 'large' ? '30%' : 'auto',
        }}
      >
        <div className={styles.textContainer}>
          <p
            className={styles.name}
            style={{
              fontSize: type === 'large' ? 15 : 10,
            }}
          >
            {name}
          </p>
          {type !== 'small' && (
            <p className={styles.time}>
              {startTime} - {endTime}
            </p>
          )}
        </div>
        {type !== 'small' && (
          <img
            className={styles.arrow}
            src={Arrow}
            style={{
              height: type === 'large' ? 20 : 14,
              marginRight: type === 'large' ? 20 : 10,
            }}
            alt="arrow"
          />
        )}
      </div>
    </div>
  )
}

export default PropertyCard
