import dayjs from 'dayjs'
import { Leaderboard } from '../types/leaderboard'
import { FilterValues, FilterKeys } from '../types/filters'

export const byEntryType = (leaderboards: Leaderboard[], values: string[]) => {
  return leaderboards.filter(
    l => !!values.find(v => v.toUpperCase() === l.category.toUpperCase())
  )
}

export const byGames = (leaderboards: Leaderboard[], values: string[]) => {
  return leaderboards.filter(l => {
    let gameName = ''
    l.filters.forEach(m => {
      if (m.metric_field.toString() === 'GameName') {
        gameName = m.value_range?.string_value
      }
    })

    return !!values.find(v => v.toUpperCase() === gameName.toUpperCase())
  })
}

export const byType = (leaderboards: Leaderboard[], values: string[]) => {
  return leaderboards.filter(
    l =>
      !!values.find(v => {
        switch (l.type) {
          case 'RankBased':
            return v === FilterValues.RankBased
          case 'GoalBased':
            return v === FilterValues.GoalBased
          default:
            return false
        }
      })
  )
}

export const byDuration = (leaderboards: Leaderboard[], values: string[]) => {
  return leaderboards.filter(l => {
    let durationFulfilled = true
    for (let i = 0; i < values.length; i += 1) {
      const value = values[i]

      const startTime = dayjs(l.live_time)
      const endTime = dayjs(l.close_time)
      switch (value) {
        case FilterValues.LessThan24hrs:
          durationFulfilled = endTime.diff(startTime, 'hour') < 24
          break
        case FilterValues.Day: // as per product requirements
          durationFulfilled =
            startTime.hour() === 0 &&
            startTime.minute() === 0 &&
            endTime.hour() === 23 &&
            endTime.minute() === 59
          break
        case FilterValues.GreaterThan24hrs:
          durationFulfilled = endTime.diff(startTime, 'hour') >= 24
          break
        default:
          durationFulfilled = true
      }

      if (!durationFulfilled) break
    }
    return durationFulfilled
  })
}

export const byEntryPrice = (
  leaderboards: Leaderboard[],
  min: number,
  max: number
) => {
  return leaderboards.filter(l => {
    const entryFee = Math.ceil(l.entry_fee.amount || 0)
    return entryFee >= min && entryFee <= max
  })
}

export const byBigBlind = (
  leaderboards: Leaderboard[],
  min: number,
  max: number
) => {
  return leaderboards.filter(l => {
    let minBigBlind = 0
    let maxBigBlind = 0
    l.filters.forEach(m => {
      if (m.metric_field.toString() === 'Bigblind') {
        minBigBlind = m.value_range.number_min || 0
        maxBigBlind = m.value_range.number_max || 0
      }
    })

    return min <= minBigBlind && max >= maxBigBlind
  })
}

export const byPointRate = (
  leaderboards: Leaderboard[],
  min: number,
  max: number
) => {
  return leaderboards.filter(l => {
    let minPointRate = 0
    let maxPointRate = 0
    l.filters.forEach(m => {
      if (m.metric_field.toString() === 'PointRate') {
        minPointRate = m.value_range.number_min || 0
        maxPointRate = m.value_range.number_max || 0
      }
    })

    return min <= minPointRate && max >= maxPointRate
  })
}

export const getFilteredLbs = (
  leaderboards: Leaderboard[],
  selectedFilters: Record<string, any>
) => {
  let filteredLeaderboards = [...leaderboards]
  Object.keys(selectedFilters).forEach(k => {
    switch (k) {
      case FilterKeys.entryType:
        filteredLeaderboards = byEntryType(
          filteredLeaderboards,
          selectedFilters[k]
        )
        break
      case FilterKeys.games:
        filteredLeaderboards = byGames(filteredLeaderboards, selectedFilters[k])
        break
      case FilterKeys.type:
        filteredLeaderboards = byType(filteredLeaderboards, selectedFilters[k])
        break
      case FilterKeys.duration:
        filteredLeaderboards = byDuration(
          filteredLeaderboards,
          selectedFilters[k]
        )
        break
      case FilterKeys.entryPrice:
        filteredLeaderboards = byEntryPrice(
          filteredLeaderboards,
          selectedFilters[k].sliderMin,
          selectedFilters[k].sliderMax
        )
        break
      case FilterKeys.bigBlind:
        filteredLeaderboards = byBigBlind(
          filteredLeaderboards,
          selectedFilters[k].sliderMin,
          selectedFilters[k].sliderMax
        )
        break
      case FilterKeys.pointRate:
        filteredLeaderboards = byPointRate(
          filteredLeaderboards,
          selectedFilters[k].sliderMin,
          selectedFilters[k].sliderMax
        )
        break
      default:
    }
  })
  return filteredLeaderboards
}
