import React, { useEffect, useState, useCallback } from 'react'

import dayjs, { Dayjs } from 'dayjs'

import styles from './NotJoinedLeaderboards.module.scss'
import LeaderboardCard from '../../components/LeaderboardCard/LeaderboardCard'
import { Leaderboard, State, MetricField } from '../../types/leaderboard'
import Empty from '../../components/Empty/Empty'
import {
  SortField,
  SortOrder,
  useSort,
  SortViewMap,
  SortIdToSort,
} from './SortContext'
import FilterIcon from '../../assets/images/filter.png'
import Filters from '../../components/Filters/Filters'
import { getFilteredLbs } from '../../helpers/filters'
import SortOrFilterView from '../../components/SortOrFilterView/SortOrFilterView'
import SortIcon from '../../assets/images/sort.png'
import CheveronDown from '../../assets/images/cheveron-down.png'
import CheveronUp from '../../assets/images/cheveron black.png'
import { GameFilterViewMap } from '../../components/Filters/filterConfig'
import Loader from '../../components/Loader/Loader'

interface Props {
  leaderboards: Leaderboard[]
  myLeaderboards: Leaderboard[]
  loading: boolean
  date: Dayjs
}

const NotJoinedLeaderboards: React.FC<Props> = ({
  leaderboards,
  myLeaderboards,
  loading,
  date,
}) => {
  const { sort, setSort } = useSort()!
  const [selectedSort, setSelectedSort] = useState<string>(
    SortViewMap[0].pills[0].id
  )
  const [showSortView, setShowSortView] = useState<boolean>(false)
  const [showGameFilterView, setShowGameFilterView] = useState<boolean>(false)
  const [showFilters, setShowFilters] = useState<boolean>(false)
  const [selectedFilters, setSelectedFilters] = useState<Record<string, any>>(
    {}
  )
  const [filteredLbs, setFilteredLbs] = useState<Leaderboard[]>(leaderboards)

  useEffect(() => {
    try {
      if (leaderboards.length > 0 || myLeaderboards.length > 0) {
        window.analytics.track('MyLeaderboardScreenViewed', {
          leaderboard_date_selected: new Date(date.format()).toISOString(),
          leaderboard_visible: [...myLeaderboards, ...leaderboards].map(
            (lb: Leaderboard) => ({
              id: lb.leaderboard_id,
              state: lb.state,
            })
          ),
          option_selected: sort.field,
          source_screen: 'leaderboard details',
        })
        window.rudderanalytics.track('MyLeaderboardScreenViewed', {
          leaderboard_date_selected: new Date(date.format()).toISOString(),
          leaderboard_visible: [...myLeaderboards, ...leaderboards].map(
            (lb: Leaderboard) => ({
              id: lb.leaderboard_id,
              state: lb.state,
            })
          ),
          option_selected: sort.field,
          source_screen: 'leaderboard details',
        })
        // @ts-ignore
        clevertap.event.push('MyLeaderboardScreenViewed', {
          leaderboard_date_selected: new Date(date.format()).toISOString(),
          option_selected: sort.field,
          source_screen: 'leaderboard details',
        })
      }
    } catch (er) {
      console.error(er)
    }
  }, [date, leaderboards, sort.field, myLeaderboards])

  useEffect(() => {
    const flbs = getFilteredLbs(leaderboards, selectedFilters)
    setFilteredLbs(flbs)
  }, [leaderboards, selectedFilters])

  useEffect(() => {
    const filters = JSON.parse(window.sessionStorage.getItem('filters'))
    if (!!filters && Object.keys(filters).length > 0) {
      setSelectedFilters(filters)
    }
  }, [])

  const sortLeaderboards = (a: Leaderboard, b: Leaderboard): number => {
    let aValue: Date | number = 0
    let bValue: Date | number = 0

    if (sort.field === SortField.Recommended) {
      aValue = a.default_sort_index || 0
      bValue = b.default_sort_index || 0
    }

    if (sort.field === SortField.PrizeMoney) {
      aValue = a.total_prize_amount || 0
      bValue = b.total_prize_amount || 0
    }

    if (sort.field === SortField.BigBlind) {
      aValue =
        a.filters.find(f => f.metric_field === MetricField.Bigblind)
          ?.value_range.number_max || 0
      bValue =
        b.filters.find(f => f.metric_field === MetricField.Bigblind)
          ?.value_range.number_max || 0
    }

    if (sort.field === SortField.Time) {
      aValue = a.state === State.Open ? a.live_time : a.close_time
      bValue = b.state === State.Open ? b.live_time : b.close_time
    }

    if (sort.field === SortField.PointRate) {
      aValue =
        a.filters.find(f => f.metric_field === MetricField.PointRate)
          ?.value_range.number_max || 0
      bValue =
        b.filters.find(f => f.metric_field === MetricField.PointRate)
          ?.value_range.number_max || 0
    }

    if (aValue < bValue) {
      if (sort.order === SortOrder.DESC) return 1
      return -1
    }

    if (aValue > bValue) {
      if (sort.order === SortOrder.DESC) return -1
      return 1
    }

    return 0
  }

  const today = dayjs().startOf('day')
  let title = ''
  if (date < today) title = 'Leaderboards History'
  if (date.isSame(today)) title = 'Join a Leaderboard'
  if (date > today) title = 'Upcoming Leaderboards'

  const applyFilters = useCallback(() => {
    setShowFilters(false)
    const flbs = getFilteredLbs(leaderboards, selectedFilters)
    setFilteredLbs(flbs)
  }, [leaderboards, selectedFilters, setShowFilters])

  const resetFilters = useCallback(() => {
    setShowFilters(false)
    const flbs = getFilteredLbs(leaderboards, {})
    setFilteredLbs(flbs)
  }, [leaderboards, setShowFilters])

  const setFilters = useCallback(
    filters => {
      window.sessionStorage.setItem('filters', JSON.stringify(filters))
      setSelectedFilters(filters)
    },
    [setSelectedFilters]
  )

  const showSortResults = (ids: string[]) => {
    setSelectedSort(ids[0])
    setSort(SortIdToSort[selectedSort])
    setShowSortView(false)
    setShowGameFilterView(false)
  }

  const showGameFilterResults = useCallback(
    (ids: string[]) => {
      let tempFilters = { ...selectedFilters }
      if (ids.length === 0) {
        delete tempFilters.games
      } else {
        tempFilters = { ...tempFilters, games: ids }
      }

      window.sessionStorage.setItem('filters', JSON.stringify(tempFilters))
      setSelectedFilters(tempFilters)

      const flbs = getFilteredLbs(leaderboards, tempFilters)
      setFilteredLbs(flbs)
      setShowGameFilterView(false)
    },
    [leaderboards, selectedFilters]
  )

  const clearGameFilter = () => {
    const tempFilters = { ...selectedFilters }
    delete tempFilters.games
    window.sessionStorage.setItem('filters', JSON.stringify(tempFilters))
    setSelectedFilters(tempFilters)

    const flbs = getFilteredLbs(leaderboards, tempFilters)
    setFilteredLbs(flbs)
    setShowGameFilterView(false)
  }

  const gameFilterPresent =
    !!selectedFilters.games && selectedFilters.games.length > 0

  return (
    <div id={styles.UpcomingLeaderboards}>
      <div className={styles.titleContainer}>
        <div className={styles.pageTitle}>{title}</div>
        <div
          className={styles.filter}
          onClick={() => {
            setShowFilters(true)
          }}
        >
          <img
            src={FilterIcon}
            className={styles.filterIcon}
            alt="filterIcon"
          />
          Filter
        </div>
      </div>
      <div className={styles.pills}>
        <div
          className={`${styles.pill} ${styles.active}`}
          onClick={() => {
            setShowGameFilterView(false)
            setShowSortView(showSortView => !showSortView)
          }}
        >
          <img src={SortIcon} className={styles.sort} alt="sortIcon" />
          {SortIdToSort[selectedSort].field.toString()}
          <img
            src={showSortView ? CheveronUp : CheveronDown}
            className={styles.cheveron}
            alt="chevron"
          />
        </div>

        <div
          className={`${styles.pill} ${gameFilterPresent ? styles.active : ''}`}
          onClick={() => {
            setShowSortView(false)
            setShowGameFilterView(showGameFilterView => !showGameFilterView)
          }}
        >
          {gameFilterPresent && selectedFilters.games.length === 1
            ? selectedFilters.games[0]
            : 'Games'}
          <img
            src={showGameFilterView ? CheveronUp : CheveronDown}
            className={styles.cheveron}
            alt="chevron"
          />
        </div>
      </div>

      {showSortView && (
        <SortOrFilterView
          viewMap={SortViewMap}
          selectedIds={[selectedSort]}
          showResults={showSortResults}
          multiSelect={false}
          leaderboards={leaderboards}
        />
      )}

      {showGameFilterView && (
        <SortOrFilterView
          viewMap={GameFilterViewMap}
          selectedIds={gameFilterPresent ? selectedFilters.games : []}
          showResults={showGameFilterResults}
          multiSelect
          showClearAll
          clearAll={clearGameFilter}
          isFilter
          leaderboards={leaderboards}
        />
      )}

      {!showSortView && !showGameFilterView && (
        <div className={styles.leaderboards}>
          {loading ? (
            <Loader />
          ) : (
            <>
              {[State.Live, State.Open, State.ResultDeclared].map(state => {
                return (
                  <React.Fragment key={state}>
                    {filteredLbs
                      ?.filter(lb => lb.state === state)
                      .sort(sortLeaderboards)
                      .map(lb => {
                        return (
                          <React.Fragment key={lb.leaderboard_id}>
                            <LeaderboardCard leaderboard={lb} date={date} />
                            <div style={{ height: '12px' }} />
                          </React.Fragment>
                        )
                      })}
                  </React.Fragment>
                )
              })}
            </>
          )}

          {!loading &&
            (!leaderboards || leaderboards.length === 0 ? (
              <div className={styles.loading}>
                <Empty message="Thats it for now! New leaderboards are coming up soon!" />
              </div>
            ) : (
              (!filteredLbs || filteredLbs.length === 0) && (
                <div className={styles.loading}>
                  <Empty message="No Results Found. Try adjusting or clearing your filters for better results" />
                </div>
              )
            ))}
          {showFilters && (
            <Filters
              selectedFilters={selectedFilters}
              setFilters={setFilters}
              applyFilters={applyFilters}
              resetFilters={resetFilters}
              setShowFilters={setShowFilters}
            />
          )}
        </div>
      )}
    </div>
  )
}

export default NotJoinedLeaderboards
