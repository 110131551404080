import React from 'react'

import styles from './DailyPrizeDistribution.module.scss'
import CollapsebaleSection from '../../../components/CollapseableSection/CollapseableSection'
import { PropertyDetails } from '../../../types/property-details'
import dayjs from 'dayjs'

interface Props {
  propertyDetails: PropertyDetails
}

const DailyPrizeDistribution: React.FC<Props> = ({ propertyDetails }) => {
  return (
    <div id={styles.DailyPrizeDistribution}>
      <CollapsebaleSection title="Daily Prize Distribution">
        <table className={styles.table}>
          <thead>
            <tr>
              <th>Day</th>
              <th className={styles.center}>Winners</th>
              <th className={styles.right}>Prize</th>
            </tr>
          </thead>
          <tbody>
            {propertyDetails?.prize_distribution?.datewise_prizes?.map(
              (d, i) => {
                return (
                  <tr key={i}>
                    <td>{dayjs(d.date).format('D MMM')}</td>
                    <td className={styles.center}>{d.winners}</td>
                    <td className={styles.right}>
                      {`₹${d.prize.toLocaleString('en-IN')}`}
                    </td>
                  </tr>
                )
              }
            )}
          </tbody>
        </table>
      </CollapsebaleSection>
    </div>
  )
}

export default DailyPrizeDistribution
